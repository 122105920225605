import React from "react";
import { BreadCrumb } from "../../../components";
import { ChevronDown, ChevronLeft, ChevronRight, Search } from "lucide-react";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Fullname",
    selector: (row) => row.fullname,
    sortable: true,
  },
  {
    name: "Username",
    selector: (row) => row.username,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Role",
    selector: (row) => row.role,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
  },
  {
    name: "Action",
    selector: (row) => row.action,
  },
];

const data = [
  {
    id: 1,
    fullname: "Beetlejuice",
    username: "1988",
    email: "beetle@gmail.com",
    role: "admin",
    status: "active",
    action: "action",
  },
  {
    id: 2,
    fullname: "Beetlejuice",
    username: "1988",
    email: "beetle@gmail.com",
    role: "admin",
    status: "active",
    action: "action",
  },
  {
    id: 3,
    fullname: "Beetlejuice",
    username: "1988",
    email: "beetle@gmail.com",
    role: "admin",
    status: "active",
    action: "action",
  },
  {
    id: 4,
    fullname: "Beetlejuice",
    username: "1988",
    email: "beetle@gmail.com",
    role: "admin",
    status: "active",
    action: "action",
  },
  {
    id: 5,
    fullname: "Beetlejuice",
    username: "1988",
    email: "beetle@gmail.com",
    role: "admin",
    status: "active",
    action: "action",
  },
  {
    id: 6,
    fullname: "Beetlejuice",
    username: "1988",
    email: "beetle@gmail.com",
    role: "admin",
    status: "active",
    action: "action",
  },
];

function ConfigurationUsers() {
  const [status, setStatus] = React.useState("All Status");

  function handleStatusChange(e) {
    setStatus(e.target.value);
  }
  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <BreadCrumb pageName={"Users"} type="configuration" />
      </section>
      <section className="mt-[1.2vw] bg-white rounded-[0.8vw] p-[1.6vw]">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-[1vw]">
            <h3 className="font-semibold text-[1.4vw]">List Users</h3>
            <div className="search items-center relative rounded-md border">
              <Search
                size={20}
                className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-2 text-gray-400"
              />
              <input
                type="text"
                className=" text-sm rounded-md py-2 pr-8 pl-4 focus:outline-blue-200"
                placeholder="Search anything. . ."
              />
            </div>
          </div>
          <div className="flex items-center gap-[0.4vw]">
            <button className="ripple bg-black rounded-[0.4vw] text-white py-[0.2vw] px-[1.5vw]">
              Export
            </button>
            <button className="ripple bg-black rounded-[0.4vw] text-white py-[0.2vw] px-[1.5vw]">
              Import
            </button>
            <button className="ripple bg-black rounded-[0.4vw] text-white py-[0.2vw] px-[1.5vw]">
              Create
            </button>
          </div>
        </div>
        <div className="relative w-[17vw] mt-[1.4vw]">
          <select
            name="status"
            id="status"
            value={status}
            onChange={handleStatusChange}
            className="text-sm rounded-md py-[0.6vw] px-[0.4vw] focus:outline-blue-200 border appearance-none w-full"
          >
            <option value={null} disabled>
              All Status
            </option>
            <option value="Active">Active</option>
            <option value="Unactive">Unctive</option>
          </select>
          <ChevronDown
            size={20}
            className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-2 text-gray-400"
          />
        </div>
        <div className="mt-[1vw]">
          <DataTable columns={columns} data={data} selectableRows />
        </div>
        <div className="mt-[1vw]">
          <div className="flex justify-between items-center mt-[0.8vw]">
            <div className="flex items-center gap-[0.2vw]">
              <button className="ripple p-[0.3vw] rounded-[0.3vw] border-[0.2vw]">
                <ChevronLeft className="w-[1vw] h-[1vw]" />
              </button>
              {[1, 2, 3, 4, 5].map((item, index) => (
                <button
                  key={index}
                  className={`ripple w-[2vw] h-[2vw] rounded-[0.3vw] border-[0.2vw] ${
                    1 === item ? "bg-gray-400 text-white border-gray-400" : ""
                  }`}
                >
                  <p className="">{item}</p>
                </button>
              ))}
              <button className="ripple p-[0.3vw] rounded-[0.3vw] border-[0.2vw]">
                <ChevronRight className="w-[1vw] h-[1vw]" />
              </button>
            </div>
            <p className="text-[0.9vw]">1 of 8 pages</p>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConfigurationUsers;
