import React from "react";
import SliderIcon from "../../../assets/icons/slider.svg?react";
import { BreadCrumb } from "../../../components";
import GridView from "./partials/GridView";
import ListView from "./partials/ListView";
import ViewButton from "./partials/ViewButton";

function CCTVDashboard() {
  const [showItems, setShowItems] = React.useState("list");
  const [CCTVActive, setCCTVActive] = React.useState(0);

  const viewMenus = ["list", "two-col", "three-col", "four-col"];
  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <BreadCrumb pageName={"CCTV List"} type={"dashboard"} />
        <div className="flex items-center gap-[0.4vw]">
          <button
            className={`ripple border border-[#D9D9D9] px-[0.6vw] py-[0.6vw] rounded-[0.3vw] cursor-pointer flex items-center justify-center gap-[0.2vw]`}
            onClick={() => setShowItems("list")}
          >
            <SliderIcon className={`w-[1vw] h-[1vw] transition`} />
            <p className="text-[0.7vw]">CCTV Area 1</p>
          </button>
          {viewMenus.map((item, index) => (
            <ViewButton
              key={index}
              viewType={item}
              activeState={showItems === item}
              setShowItems={setShowItems}
            />
          ))}
        </div>
      </section>
      <ListView
        CCTVActive={CCTVActive}
        setCCTVActive={setCCTVActive}
        showItems={showItems}
      />
      <GridView showItems={showItems} />
    </>
  );
}

export default CCTVDashboard;
