import React from "react";
import { Outlet } from "react-router-dom";
import { DashboardNavbar, LoadPage, SidebarConfiguration } from "../components";
import { useSelector } from "react-redux";

function DashboardLayout() {
  const sidebarState = useSelector((state) => state.navbar.show);
  return (
    <div className="flex w-full min-h-screen">
      <div
        className={`${
          sidebarState ? "w-[17vw]" : ""
        } transition-all hidden lg:block bg-white border-r-2`}
      >
        <div
          className={`fixed hidden lg:block ${
            sidebarState ? "w-[17vw]" : ""
          } transition-all top-[4vw] left-0`}
        >
          <SidebarConfiguration />
        </div>
      </div>
      <div className="flex-1 flex flex-col relative">
        <DashboardNavbar />
        <main className="bg-[#F8FAFC] h-full px-[1vw] pt-[3.5vw]">
          <LoadPage>
            <Outlet />
          </LoadPage>
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
