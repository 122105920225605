import { ChevronRight } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

function BreadCrumb({ pageName, type, subMenus }) {
  const navigate = useNavigate();
  return (
    <div className="flex items-center gap-[1vw]">
      <h4 className="font-bold text-black text-[1.4vw]">{pageName}</h4>
      <div className="h-[2vw] w-[0.1vw] bg-gray-200"></div>
      <div className="flex items-center gap-[0.6vw]">
        <p
          onClick={() => navigate(`/${type.toLowerCase()}`)}
          className="text-[1vw] font-semibold text-[#7398FF] cursor-pointer capitalize"
        >
          {type}
        </p>
        {/* {pageName !== "Dashboard" && !subMenus && (
          <>
            <ChevronRight className="w-[1vw] h-[1vw]" />
            <p className="text-[1vw] font-semibold cursor-pointer">
              {pageName}
            </p>
          </>
        )} */}
        {subMenus && subMenus.length > 0 ? (
          subMenus.map((item, index) => (
            <React.Fragment key={index}>
              <ChevronRight className="w-[1vw] h-[1vw]" />
              <p
                className="text-[1vw] font-semibold cursor-pointer"
                onClick={() => navigate(item.path)}
              >
                {item.name}
              </p>
            </React.Fragment>
          ))
        ) : pageName !== "Dashboard" ? (
          <>
            <ChevronRight className="w-[1vw] h-[1vw]" />
            <p className="text-[1vw] font-semibold cursor-pointer">
              {pageName}
            </p>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default BreadCrumb;
