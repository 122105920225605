import { MapContainer, Marker, TileLayer } from "react-leaflet";
import GeoSearch from "./OSMapGeoSearch";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, -32],
});

L.Marker.prototype.options.icon = DefaultIcon;

export const OSMapEnvironment = ({
  clicker,
  isView,
  setSearchMarker,
  searchMarker,
  position,
  isScroll,
  isDragging,
}) => {
  console.log(position);
  return (
    <MapContainer
      center={[-6.1753924, 106.8245779]}
      zoom={12}
      zoomControl={false}
      attributionControl={false}
      style={{ height: "100%", width: "100%" }}
      dragging={isDragging}
      scrollWheelZoom={isScroll}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url={"https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"}
      />
      {!isView && position && <Marker position={position} />}
      {isView && (
        <>
          <GeoSearch
            setSearchMarker={setSearchMarker}
            searchMarker={searchMarker}
          />
          {clicker}
          {position && <Marker position={position} />}
          {/* Marker for user click position */}
          {searchMarker && <Marker position={searchMarker} />}{" "}
          {/* Render search marker if defined */}
        </>
      )}
    </MapContainer>
  );
};
