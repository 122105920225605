import React from "react";
import { Bell, ChevronDown, Menu, Search } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { navMenus } from "../../utils/menus";
import { useDispatch, useSelector } from "react-redux";
import { showSidebar } from "../../redux/slices/NavbarSlice";
import { setLogout } from "../../redux/slices/AuthSlice";

function DashboradNavbar() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sidebarState = useSelector((state) => state.navbar.show);
  const [isProfile, setIsProfile] = React.useState(false);

  function handleLogout() {
    dispatch(setLogout());
    navigate("/");
  }

  return (
    <nav className="py-[1vw] sticky left-0 top-0 z-10">
      <div className="fixed w-full bg-white left-0 top-0 border-b-[0.1vw] flex items-center justify-between ">
        <div className="flex">
          <div className="wrapper-logo py-[0.4vw] w-[17vw] border-r-[0.1vw] border-b-[0.1vw] flex items-center gap-[0.8vw] px-[0.8vw]">
            <Menu
              className="cursor-pointer w-[1.2vw] h-[1.2vw]"
              onClick={() => dispatch(showSidebar(!sidebarState))}
            />
            <div className="flex items-center ml-[0.8vw] gap-2">
              <img
                src="https://assets.skota.id/dev.superapps/images/logo-skota.png"
                className="w-[2.5vw] h-[2.5vw]"
              />
              <div className="">
                <h4 className="font-roboto font-bold text-[1.2vw]">SKOTA</h4>
                <p className="text-[0.9vw]">Ecosystem</p>
              </div>
            </div>
          </div>
          <div className="nav-menus flex items-center gap-[0.8vw]">
            {navMenus.map((item, index) => (
              <button
                key={index}
                className={`ripple px-[0.8vw] py-1.5 rounded-tr-md rounded-tl-md text-[1vw] flex items-center gap-[0.3vw] ${
                  pathname.split("/")[1] === item.name.toLowerCase()
                    ? "border-b-[0.1vw] border-black pb-1 text-black font-semibold"
                    : "text-[#A5A5A5]"
                }`}
                onClick={() => navigate(item.pathname)}
              >
                <item.icon
                  className={`w-[1vw] h-[1vw] ${
                    pathname.split("/")[1] === item.name.toLowerCase()
                      ? "svg-active"
                      : ""
                  }`}
                />
                {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className="right flex items-center gap-2">
          <div className="search items-center relative rounded-md ">
            <Search
              size={20}
              className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-2 text-gray-400"
            />
            <input
              type="text"
              className=" text-sm rounded-md py-2 pl-8 bg-[#EDF1F5] focus:outline-blue-200"
              placeholder="Search anything. . ."
            />
          </div>
          <div className="profile flex gap-2 relative">
            <button className="ripple p-2 rounded-md">
              <Bell fill="#3A3A3A" className="w-[1.2vw] h-[1.2vw]" />
            </button>
            <button
              className="user rounded-md flex gap-2 items-center ripple"
              onClick={() => setIsProfile(!isProfile)}
            >
              <div className="w-[2vw] h-[2vw] rounded-full bg-green-500 flex items-center justify-center">
                <p className="text-white">A</p>
              </div>
              <div className="text-left">
                <h5 className="font-semibold text-[0.8vw]">Sytem</h5>
                <p className="text-[0.8vw]">Superadmin</p>
              </div>
              <ChevronDown size={16} />
            </button>
            <div
              className={`absolute bg-white shadow-md min-w-[15vw] -right-[0vw] top-[3.5vw] rounded-[0.9vw] p-[0.8vw] hidden opacity-0 transition-all duration-300  ${
                isProfile ? "opacity-100 block" : ""
              }`}
            >
              <button
                className="ripple  w-full py-1.5 border rounded-[.2vw]"
                onClick={() => handleLogout()}
              >
                <p className="text-[.8vw] text-red-600">Logout</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default DashboradNavbar;
