import React from "react";
import GridItem from "./GridItem";
import Pagination from "./Pagination";

function GridView({ showItems }) {
  const [paginationActive, setPaginationActive] = React.useState(1);
  return (
    <>
      {showItems !== "list" && (
        <section className="mt-[1.2vw] pb-[1.6vw]">
          <div
            className={`grid ${
              showItems === "two-col"
                ? "grid-cols-2"
                : showItems === "three-col"
                ? "grid-cols-3"
                : "grid-cols-4"
            } gap-2`}
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
              <GridItem key={item} />
            ))}
          </div>
          <Pagination paginationActive={paginationActive} />
        </section>
      )}
    </>
  );
}

export default GridView;
