import { GoogleMap, MarkerF, OverlayView } from "@react-google-maps/api";
import React from "react";
import { useSelector } from "react-redux";
import { dummyPOI, GMapType } from "../../utils";
import { BottomController } from "./partials/BottomController";
import CustomPopup from "./partials/CustomPopup";

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

function MapComponent({ geoData, style }) {
  const [zoom, setZoom] = React.useState(12);
  const [tooltipData, setTooltipData] = React.useState({
    content: "",
    position: null,
  });
  const [schoolPosition, setSchoolPosition] = React.useState({
    lat: -6.1753924,
    lng: 106.8245779,
  });
  const [activePopup, setActivePopup] = React.useState(null);
  // set map type
  const [isSetMap, setIsSetMap] = React.useState(false);
  const [mapType, setMapType] = React.useState(GMapType[0]);
  // map pins
  const mapPins = useSelector((state) => state.map.mapPinTypes);
  // map instance
  const [mapInstance, setMapInstance] = React.useState(null);

  function handleZoomIn() {
    setZoom((prevState) => prevState + 1);
  }
  function handleZoomOut() {
    setZoom((prevState) => prevState - 1);
  }

  const onLoad = React.useCallback(
    (map) => {
      if (geoData) {
        map.data.addGeoJson(geoData);
        map.data.setStyle({
          fillColor: "#3d5569",
          strokeColor: "white",
          strokeWeight: 2,
        });

        map.data.addListener("click", (event) => {
          // map.data.revertStyle(); // Reset any previous styles
          // map.data.overrideStyle(event.feature, { fillColor: "yellow" });
          const feature = event.feature;
          const provinsi = feature.getProperty("provinsi");
          const kabkot = feature.getProperty("kabkot");
          const position = event.latLng;
          setTooltipData({ content: `${provinsi}, ${kabkot}`, position });
        });

        map.data.addListener("mouseover", (event) => {
          map.data.revertStyle();
          map.data.overrideStyle(event.feature, { fillColor: "yellow" });
          setTooltipData({ content: "", position: null });
        });

        map.data.addListener("mouseout", () => {
          map.data.revertStyle();
        });
      }
    },
    [geoData]
  );

  const getPixelPositionOffset = (pixelOffset) => (width, height) => ({
    x: -(width / 2) + pixelOffset.x,
    y: -(height / 2) + pixelOffset.y,
  });

  const filteredData = dummyPOI.filter((item) =>
    mapPins.some(
      (category) => category.toLowerCase() === item.category.toLowerCase()
    )
  );

  // Update the map when geoData changes
  React.useEffect(() => {
    if (mapInstance) {
      if (geoData) {
        // Clear existing GeoJSON data before adding new data
        mapInstance.data.forEach((feature) => {
          mapInstance.data.remove(feature);
        });
        onLoad(mapInstance); // Reapply the onLoad logic to the map
      } else {
        // Reset the map if geoData is null
        mapInstance.data.forEach((feature) => {
          mapInstance.data.remove(feature); // Clear all features from the map
        });
      }
    }
  }, [geoData, mapInstance, onLoad]);

  return (
    <>
      <div className="fixed top-0">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={schoolPosition}
          zoom={zoom}
          options={{
            gestureHandling: "greedy",
            disableDefaultUI: true,
            mapTypeId: mapType.name.toLowerCase(), // Options: 'roadmap', 'satellite', 'hybrid', 'terrain'
            styles: [
              {
                featureType: "poi",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.airport",
                elementType: "labels",
                stylers: [{ visibility: "off" }],
              },
            ],
          }}
          onLoad={(map) => {
            setMapInstance(map); // Save the map instance in state
            onLoad(map); // Call the onLoad function initially
          }}
        >
          {/* POI */}
          {filteredData.map((data, index) => (
            <MarkerF
              key={index}
              position={{ lat: data.location.lat, lng: data.location.lng }}
              onClick={() => {
                if (activePopup?.name === data.name)
                  return setActivePopup(null);
                setSchoolPosition({
                  lat: data.location.lat,
                  lng: data.location.lng,
                });
                setZoom(14);
                setActivePopup({
                  name: data.name,
                  type: data.type,
                  lat: data.location.lat,
                  lng: data.location.lng,
                });
              }}
              icon={{
                url: data.icon,
              }}
            ></MarkerF>
          ))}
          {/* onClick TopoJSON */}
          {tooltipData?.content !== "" && tooltipData?.position && (
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={{
                lat: tooltipData?.position.lat(),
                lng: tooltipData?.position.lng(),
              }}
              getPixelPositionOffset={getPixelPositionOffset({
                x: 0,
                y: -32,
              })}
            >
              <div className="popup-tip-anchor">
                <div className="popup-bubble-anchor">
                  <div className="popup-bubble-content">
                    <CustomPopup>
                      <h2>{tooltipData.content}</h2>
                    </CustomPopup>
                  </div>
                </div>
              </div>
            </OverlayView>
          )}
          {/* onClick CCTV */}
          {activePopup && (
            <OverlayView
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              position={{ lat: activePopup.lat, lng: activePopup.lng }}
              getPixelPositionOffset={getPixelPositionOffset({
                x: 0,
                y: -32,
              })}
            >
              <div className="popup-tip-anchor">
                <div className="popup-bubble-anchor">
                  <div className="popup-bubble-content">
                    <CustomPopup type={activePopup.type} map={"GMap"} />
                  </div>
                </div>
              </div>
            </OverlayView>
          )}
          <div className="fixed bottom-7 left-5 z-[11] flex gap-[1vw]">
            <div
              className="w-[6.5vw] h-[6.5vw] rounded-[0.6vw] cursor-pointer overflow-hidden border-4 shadow-md border-white"
              onClick={() => setIsSetMap(!isSetMap)}
            >
              <img src={mapType.img} alt="" />
            </div>
            {isSetMap && (
              <div className="bg-white rounded-[0.6vw] p-[0.7vw] flex gap-[0.8vw] shadow-md">
                {GMapType.map((item, index) => (
                  <div
                    key={index}
                    className="wrapper cursor-pointer"
                    onClick={() => setMapType(item)}
                  >
                    <div className="w-[3.9vw] h-[3.9vw] rounded-[0.4vw] overflow-hidden border border-gray-300">
                      <img src={item.img} alt="" />
                    </div>
                    <p className="text-[0.7vw] text-center mt-1">{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <BottomController
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
          />
        </GoogleMap>
      </div>
    </>
  );
}

export default MapComponent;
