import React from "react";

function Switcher({ isOn, handleToggle }) {
  return (
    <div
      onClick={handleToggle}
      className={`w-[2.1vw] h-[1vw] flex items-center rounded-full p-1 cursor-pointer transition-colors duration-300 ${
        isOn ? "bg-[#2563EB]" : "bg-gray-300"
      }`}
    >
      <div
        className={`w-[1vw] h-[1vw] rounded-full shadow-md transform transition-transform duration-300 ${
          isOn
            ? "translate-x-[0.9vw] bg-white"
            : "-translate-x-[0.2vw] bg-[#2563EB] border"
        }`}
      ></div>
    </div>
  );
}

export default Switcher;
