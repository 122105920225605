import { Blocks, MapIcon, MapPin, Minus, Plus, RefreshCw } from "lucide-react";
import React from "react";
import { useMap } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { switchMap } from "../../../redux/slices/MapSlice";

function Popup({ title, data, active, type, mapType }) {
  const dispatch = useDispatch();
  const style = {
    marker: "bottom-[2.9vw] -left-[3.6vw] -right-[3.2vw]",
    widget: "bottom-[2.9vw] -left-[3.6vw] -right-[3.2vw]",
    map: "bottom-[2.9vw] -left-[3.6vw] -right-[3.2vw]",
  };
  return (
    <>
      <div
        className={`absolute bg-black bg-opacity-70 shadow-md min-w-[10vw] ${
          style[type]
        }  mx-auto rounded-md p-[0.4vw] translate-y-40 transition-all duration-300 ${
          active
            ? "opacity-100 translate-y-[2px] z-[1000]"
            : "hidden opacity-100"
        }`}
      >
        <div className="pb-[0.2vw] border-b-[0.2vw]">
          <h4 className="text-[0.8vw]">{title}</h4>
        </div>
        <div className="mt-[0.4vw] flex flex-col gap-[0.4vw]">
          {data?.map((item, index) => (
            <div
              key={index}
              className="form-group flex items-center gap-[0.4vw]"
            >
              <input
                id={`map-${index}`}
                name={`map`}
                type="radio"
                value={item}
                checked={mapType === item}
                onChange={() => dispatch(switchMap(item))}
              />
              <label
                htmlFor={`map-${index}`}
                className="cursor-pointer block text-[0.8vw]"
                onClick={() => dispatch(switchMap(item))}
              >
                {item}
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export function BottomController({ zoom, handleZoomIn, handleZoomOut, type }) {
  const [active, setActive] = React.useState(null);
  const { mapType, maps: mapsData } = useSelector((state) => state.map);

  function handlePopup(menu) {
    if (menu === active) return setActive(null);
    setActive(menu);
  }

  return (
    <div className="fixed w-[19.2vw] bottom-[2vw] left-0 right-0 mx-auto rounded-[0.7vw] bg-black bg-opacity-60 z-[1000]">
      <div className="flex justify-between items-center text-white relative">
        <div className="relative">
          <Popup
            active={active === "marker"}
            title={"Marker Option"}
            type="marker"
            data={["dummy1", "dummy2"]}
          />
          <button
            onClick={() => handlePopup("marker")}
            className="ripple p-[0.8vw]"
          >
            <MapPin
              color="#FFFFFF"
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
          </button>
        </div>
        <div className="relative">
          <Popup
            active={active === "widget"}
            title={"Widget Option"}
            type="widget"
            data={["dummy1", "dummy2"]}
          />
          <button
            onClick={() => handlePopup("widget")}
            className="ripple p-[0.8vw]"
          >
            <Blocks
              color="#FFFFFF"
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
          </button>
        </div>
        <div className="relative">
          <Popup
            active={active === "map"}
            title={"Map Option"}
            type="map"
            data={mapsData}
            mapType={mapType}
          />
          <button
            onClick={() => handlePopup("map")}
            className="ripple p-[0.8vw]"
          >
            <MapIcon
              color="#FFFFFF"
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
          </button>
        </div>
        <button className="ripple p-[0.8vw]" onClick={handleZoomIn}>
          <Plus color="#FFFFFF" className="cursor-pointer w-[1vw] h-[1vw]" />
        </button>
        <button className="ripple p-[0.8vw]" onClick={handleZoomOut}>
          <Minus color="#FFFFFF" className="cursor-pointer w-[1vw] h-[1vw]" />
        </button>
        <button
          className="ripple p-[0.8vw]"
          onClick={() => window.location.reload()}
        >
          <RefreshCw
            color="#FFFFFF"
            className="cursor-pointer w-[1vw] h-[1vw]"
          />
        </button>
      </div>
    </div>
  );
}

export function BottomControllerOSMap({ zoom, handleZoomIn, handleZoomOut }) {
  const [active, setActive] = React.useState(null);
  const { mapType, maps: mapsData } = useSelector((state) => state.map);

  function handlePopup(menu) {
    if (menu === active) return setActive(null);
    setActive(menu);
  }

  const map = useMap(); // Access the map instance

  React.useEffect(() => {
    // Check if the map instance is valid
    if (map) {
      map.setZoom(zoom); // Update the zoom level
    }
  }, [zoom, map]);

  return (
    <div className="fixed w-[19.2vw] bottom-[2vw] left-0 right-0 mx-auto rounded-[0.7vw] bg-black bg-opacity-60 z-[1000]">
      <div className="flex justify-between items-center text-white relative">
        <div className="relative">
          <Popup
            active={active === "marker"}
            title={"Marker Option"}
            type="marker"
            data={["dummy1", "dummy2"]}
          />
          <button
            onClick={() => handlePopup("marker")}
            className="ripple p-[0.8vw]"
          >
            <MapPin
              color="#FFFFFF"
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
          </button>
        </div>
        <div className="relative">
          <Popup
            active={active === "widget"}
            title={"Widget Option"}
            type="widget"
            data={["dummy1", "dummy2"]}
          />
          <button
            onClick={() => handlePopup("widget")}
            className="ripple p-[0.8vw]"
          >
            <Blocks
              color="#FFFFFF"
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
          </button>
        </div>
        <div className="relative">
          <Popup
            active={active === "map"}
            title={"Map Option"}
            type="map"
            data={mapsData}
            mapType={mapType}
          />
          <button
            onClick={() => handlePopup("map")}
            className="ripple p-[0.8vw]"
          >
            <MapIcon
              color="#FFFFFF"
              className="cursor-pointer w-[1vw] h-[1vw]"
            />
          </button>
        </div>
        <button className="ripple p-[0.8vw]" onClick={handleZoomIn}>
          <Plus color="#FFFFFF" className="cursor-pointer w-[1vw] h-[1vw]" />
        </button>
        <button className="ripple p-[0.8vw]" onClick={handleZoomOut}>
          <Minus color="#FFFFFF" className="cursor-pointer w-[1vw] h-[1vw]" />
        </button>
        <button
          className="ripple p-[0.8vw]"
          onClick={() => window.location.reload()}
        >
          <RefreshCw
            color="#FFFFFF"
            className="cursor-pointer w-[1vw] h-[1vw]"
          />
        </button>
      </div>
    </div>
  );
}
