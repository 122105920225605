import { Camera, TriangleAlert } from "lucide-react";
import React from "react";

function VehiclePopup() {
  return (
    <>
      <div className="flex items-center justify-center bg-gray-300 p-[1vw] rounded-[0.4vw] w-[36vw] h-[14vw]">
        <Camera className="w-[3vw] h-[3vw]" />
      </div>
      <div className="w-1/2 flex flex-col justify-between">
        <div className="wrapper">
          <div className="popup-header flex items-center justify-between pb-[0.4vw] border-b-2">
            <p className="text-[1vw] font-bold reset-margin">Camera 02</p>
            <div className="flex items-center justify-end gap-[0.4vw]">
              <div
                className={`w-[0.6vw] h-[0.6vw] rounded-full bg-green-400`}
              ></div>
              <p className="font-bold text-[0.9vw]">On</p>
            </div>
          </div>
          <div className="popup-content mt-[0.7vw]">
            <h4 className="text-[1vw] font-bold mb-[0.4vw]">
              Vehicle Counting
            </h4>
            <p className="text-[0..6vw] text-[#747474]">21/08/2022</p>
            <h5 className="text-[0.8vw] text-[#747474] mt-[0.8vw]">56 Cars</h5>
            <h5 className="text-[0.8vw] text-[#747474] mt-[0.4vw]">
              19 Motorcycle
            </h5>
          </div>
        </div>
        <button className="ripple bg-[#A3A5B1] text-white w-full rounded-[0.4vw] py-[0.4vw] text-[0.7vw]">
          See Details
        </button>
      </div>
    </>
  );
}

function PeoplePopup() {
  return (
    <>
      <div className="flex items-center justify-center bg-gray-300 p-[1vw] rounded-[0.4vw] w-[36vw] h-[14vw]">
        <Camera className="w-[3vw] h-[3vw]" />
      </div>
      <div className="w-1/2 flex flex-col justify-between">
        <div className="wrapper">
          <div className="popup-header flex items-center justify-between pb-[0.4vw] border-b-2">
            <p className="text-[1vw] font-bold reset-margin">Camera 02</p>
            <div className="flex items-center justify-end gap-[0.4vw]">
              <div
                className={`w-[0.6vw] h-[0.6vw] rounded-full bg-green-400`}
              ></div>
              <p className="font-bold text-[0.9vw]">On</p>
            </div>
          </div>
          <div className="popup-content mt-[0.7vw]">
            <h4 className="text-[1vw] font-bold mb-[0.4vw]">People Counting</h4>
            <p className="text-[0..6vw] text-[#747474]">21/08/2022</p>
            <h5 className="text-[0.8vw] text-[#747474] mt-[0.8vw]">
              52 Children
            </h5>
            <h5 className="text-[0.8vw] text-[#747474] mt-[0.4vw]">
              108 Adults
            </h5>
          </div>
        </div>
        <button className="ripple bg-[#A3A5B1] text-white w-full rounded-[0.4vw] py-[0.4vw] text-[0.7vw]">
          See Details
        </button>
      </div>
    </>
  );
}

function SmokeFirePopup() {
  return (
    <>
      <div className="flex items-center justify-center bg-gray-300 p-[1vw] rounded-[0.4vw] w-[36vw] h-[14vw]">
        <Camera className="w-[3vw] h-[3vw]" />
      </div>
      <div className="w-1/2 flex flex-col justify-between">
        <div className="wrapper">
          <div className="popup-header flex items-center justify-between pb-[0.4vw] border-b-2">
            <p className="text-[1vw] font-bold reset-margin">Camera 02</p>
            <div className="flex items-center justify-end gap-[0.4vw]">
              <div
                className={`w-[0.6vw] h-[0.6vw] rounded-full bg-green-400`}
              ></div>
              <p className="font-bold text-[0.9vw]">On</p>
            </div>
          </div>
          <div className="popup-content mt-[0.7vw]">
            <h4 className="text-[1vw] font-bold mb-[0.4vw]">
              Smoke/Fire Detection
            </h4>
            <div className="bg-[#FD3636] rounded-[0.3vw] text-white flex items-center justify-between px-[0.6vw] py-[0.4vw] mb-[0.4vw]">
              <span className="text-[0.8vw]">Smoke/Fire Detected!</span>
              <TriangleAlert className="w-[1.2vw] h-[1.2vw]" />
            </div>
            <p className="text-[0..6vw] text-[#747474]">21/08/2022</p>
          </div>
        </div>
        <button className="ripple bg-[#A3A5B1] text-white w-full rounded-[0.4vw] py-[0.4vw] text-[0.7vw]">
          See Details
        </button>
      </div>
    </>
  );
}

function HatPopup() {
  return (
    <>
      <div className="flex items-center justify-center bg-gray-300 p-[1vw] rounded-[0.4vw] w-[36vw] h-[14vw]">
        <Camera className="w-[3vw] h-[3vw]" />
      </div>
      <div className="w-1/2 flex flex-col justify-between">
        <div className="wrapper">
          <div className="popup-header flex items-center justify-between pb-[0.4vw] border-b-2">
            <p className="text-[1vw] font-bold reset-margin">Camera 02</p>
            <div className="flex items-center justify-end gap-[0.4vw]">
              <div
                className={`w-[0.6vw] h-[0.6vw] rounded-full bg-green-400`}
              ></div>
              <p className="font-bold text-[0.9vw]">On</p>
            </div>
          </div>
          <div className="popup-content mt-[0.7vw]">
            <h4 className="text-[1vw] font-bold mb-[0.4vw]">
              No Hard Hat Detection
            </h4>
            <div className="bg-[#FD3636] rounded-[0.3vw] text-white flex items-center justify-between px-[0.6vw] py-[0.4vw] mb-[0.4vw]">
              <span className="text-[0.8vw]">No Hard Hat Detected</span>
              <TriangleAlert className="w-[1.2vw] h-[1.2vw]" />
            </div>
            <p className="text-[0..6vw] text-[#747474]">
              3 Workers is currently not wearing a hard hat
            </p>
          </div>
        </div>
        <button className="ripple bg-[#A3A5B1] text-white w-full rounded-[0.4vw] py-[0.4vw] text-[0.7vw]">
          See Details
        </button>
      </div>
    </>
  );
}

function CustomPopup({ type, map, children }) {
  switch (type) {
    case "environment":
      return (
        <div
          className={`flex gap-[1vw] w-full ${
            map === "GMap" ? "bg-white p-[1vw] rounded-[0.7vw]" : ""
          }`}
        >
          <VehiclePopup />
        </div>
      );
    case "people":
      return (
        <div
          className={`flex gap-[1vw] w-full ${
            map === "GMap" ? "bg-white p-[1vw] rounded-[0.7vw]" : ""
          }`}
        >
          <PeoplePopup />
        </div>
      );
    case "smoke_fire":
      return (
        <div
          className={`flex gap-[1vw] w-full ${
            map === "GMap" ? "bg-white p-[1vw] rounded-[0.7vw]" : ""
          }`}
        >
          <SmokeFirePopup />
        </div>
      );
    case "hat":
      return (
        <div
          className={`flex gap-[1vw] w-full ${
            map === "GMap" ? "bg-white p-[1vw] rounded-[0.7vw]" : ""
          }`}
        >
          <HatPopup />
        </div>
      );
    default:
      return (
        <div className={`w-[20vw] bg-white p-[1vw] rounded-[0.7vw]`}>
          {children}
        </div>
      );
  }
}

export default CustomPopup;
