import { AnimatePresence } from "framer-motion";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import { DashboardConfigurationLayout, DashboardLayout } from "../layouts";
import {
  CCTVDashboard,
  CityDashboard,
  ConfigurationCategory,
  ConfigurationCreateEnvironment,
  ConfigurationDashboard,
  ConfigurationDataSource,
  ConfigurationEditEnvironment,
  ConfigurationHomepage,
  ConfigurationListEnvironment,
  ConfigurationPOI,
  ConfigurationRoles,
  ConfigurationUsers,
  FTCDashboard,
  IOTDashboard,
  LoginPage,
  Maps,
  MediaDashboard,
  OTPPage,
  RegisterPage,
  SalesDashboard,
} from "../pages";
import CantGoBack from "./CantGoBack";
import ProtectedRoute from "./ProtectedRoute";

function AnimatedRoutes() {
  return (
    <>
      <ErrorBoundary>
        <AnimatePresence mode="wait" initial={false}>
          <Routes>
            <Route index path="/" element={<Navigate to={"/auth"} />} />
            {/* auth */}
            <Route element={<CantGoBack />}>
              <Route path="/auth">
                <Route index element={<Navigate to={"/auth/login"} />} />
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="otp" element={<OTPPage />} />
              </Route>
            </Route>
            <Route element={<ProtectedRoute />}>
              {/* map */}
              <Route path="/map" element={<Maps />} />
              {/* dashboard */}
              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route index element={<CityDashboard />} />
                <Route path="media" element={<MediaDashboard />} />
                <Route path="sales" element={<SalesDashboard />} />
                <Route path="iot" element={<IOTDashboard />} />
                <Route path="ftc" element={<FTCDashboard />} />
                <Route path="cctv" element={<CCTVDashboard />} />
              </Route>
              {/* configuration dashboard */}
              <Route
                path="/configuration"
                element={<DashboardConfigurationLayout />}
              >
                <Route index element={<Navigate to="users" />} />
                <Route path="users" element={<ConfigurationUsers />} />
                <Route path="roles" element={<ConfigurationRoles />} />
                <Route path="category" element={<ConfigurationCategory />} />
                <Route path="homepage" element={<ConfigurationHomepage />} />
                <Route path="poi" element={<ConfigurationPOI />} />
                <Route
                  path="environment"
                  element={<ConfigurationListEnvironment />}
                />
                <Route
                  path="environment/create"
                  element={<ConfigurationCreateEnvironment />}
                />
                <Route
                  path="environment/edit/:id"
                  element={<ConfigurationEditEnvironment />}
                />
                <Route path="dashboard" element={<ConfigurationDashboard />} />
                <Route
                  path="data-source"
                  element={<ConfigurationDataSource />}
                />
              </Route>
            </Route>
          </Routes>
          {/* <React.Suspense fallback={<Loader />}>
          <Routes key={location.pathname} location={location}>
            <Route index path="/" element={<Maps />} />
            <Route path="/dashboard" element={<DashboardLayout />}>
              <Route index element={<Navigate to="city" />} />
              <Route path="city" element={<CityDashboard />} />
              <Route path="media" element={<MediaDashboard />} />
              <Route path="sales" element={<SalesDashboard />} />
              <Route path="iot" element={<IOTDashboard />} />
              <Route path="ftc" element={<FTCDashboard />} />
              <Route path="cctv" element={<CCTVDashboard />} />
            </Route>
            <Route
              path="/configuration"
              element={<DashboardConfigurationLayout />}
            >
              <Route index element={<Navigate to="users" />} />
              <Route path="users" element={<ConfigurationUsers />} />
              <Route path="roles" element={<ConfigurationRoles />} />
              <Route path="category" element={<ConfigurationCategory />} />
              <Route path="homepage" element={<ConfigurationHomepage />} />
              <Route path="poi" element={<ConfigurationPOI />} />
              <Route
                path="environment"
                element={<ConfigurationEnvironment />}
              />
              <Route path="dashboard" element={<ConfigurationDashboard />} />
              <Route path="data-source" element={<ConfigurationDataSource />} />
            </Route>
          </Routes>
        </React.Suspense> */}
        </AnimatePresence>
      </ErrorBoundary>
    </>
  );
}

export default AnimatedRoutes;
