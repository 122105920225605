import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapType: "OSMap",
  maps: ["OSMap", "GMap"],
  mapPinTypes: [],
  geoType: "",
};

export const MapSlice = createSlice({
  name: "MapSwitcher",
  initialState,
  reducers: {
    switchMap: (state, action) => {
      state.mapType = action.payload;
    },
    setMapPinTypes: (state, action) => {
      state.mapPinTypes = action.payload;
    },
    setGeoType: (state, action) => {
      state.geoType = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { switchMap, setMapPinTypes, setGeoType } = MapSlice.actions;

export default MapSlice.reducer;
