// import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import {
  GeoJSON,
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";
import { useSelector } from "react-redux";
import { dummyPOI, OSMapType } from "../../utils";
import { BottomControllerOSMap } from "./partials/BottomController";
import CustomPopup from "./partials/CustomPopup";

function MapCenterUpdater({ center }) {
  const map = useMap();
  map.setView(center, map.getZoom());
  return null;
}

function OSMap({ geoData }) {
  const [zoom, setZoom] = React.useState(12);
  const [schoolPosition, setSchoolPosition] = React.useState([
    -6.1753924, 106.8245779,
  ]);
  // set map type
  const [isSetMap, setIsSetMap] = React.useState(false);
  const [mapType, setMapType] = React.useState(OSMapType[0]);
  // map pins
  const mapPins = useSelector((state) => state.map.mapPinTypes);

  const handleZoomIn = () => {
    setZoom((prevZoom) => prevZoom + 1);
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 1, 1)); // Prevent zooming out below 1
  };

  // geojson
  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: (e) => {
        const layer = e.target;
        layer.setStyle({
          fillColor: "orange", // Change to your desired hover color
          fillOpacity: 0.7,
        });
      },
      mouseout: (e) => {
        const layer = e.target;
        layer.setStyle({
          color: "white",
          weight: 2,
          fillColor: "steelblue",
          fillOpacity: 0.6,
        });
      },
    });

    // Optional: Bind a popup to show more information on click
    layer.bindPopup(`Region: ${feature.properties.kabkot}`);
  };

  // geojson style
  // const getPolygonStyle = (feature) => {
  //   // Change color based on the provinsi or any other property
  //   let fillColor;
  //   switch (feature.properties.kabkot) {
  //     case "Jakarta Selatan":
  //       fillColor = "#FF5733"; // Example color for Jakarta Selatan
  //       break;
  //     case "Jakarta Timur":
  //       fillColor = "#33FF57"; // Example color for Jakarta Timur
  //       break;
  //     case "Jakarta Pusat":
  //       fillColor = "#3357FF"; // Example color for Jakarta Pusat
  //       break;
  //     case "Jakarta Barat":
  //       fillColor = "#FF33A6"; // Example color for Jakarta Barat
  //       break;
  //     case "Jakarta Utara":
  //       fillColor = "#FFC300"; // Example color for Jakarta Utara
  //       break;
  //     default:
  //       fillColor = "#3388ff"; // Default color for other regions
  //   }

  //   return {
  //     color: "#000000", // Border color
  //     weight: 1,
  //     fillColor: fillColor,
  //     fillOpacity: 0.6,
  //   };
  // };

  const filteredData = dummyPOI.filter((item) =>
    mapPins.some(
      (category) => category.toLowerCase() === item.category.toLowerCase()
    )
  );

  return (
    <>
      <div className="fixed top-0 w-full min-h-screen">
        <MapContainer
          center={schoolPosition}
          zoom={zoom}
          zoomControl={false}
          attributionControl={false}
        >
          <MapCenterUpdater center={schoolPosition} />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={mapType.url}
          />
          {geoData && (
            <GeoJSON
              key={JSON.stringify(geoData)} // Unique key to force re-render
              data={geoData}
              onEachFeature={onEachFeature}
              style={() => ({
                color: "white",
                weight: 2,
                fillColor: "steelblue",
                fillOpacity: 0.6,
              })}
            />
          )}
          {filteredData.map((data, index) => (
            <Marker
              icon={L.icon({
                iconUrl: data.icon,
                iconSize: [32, 32], // size of the icon
                iconAnchor: [16, 32], // point of the icon which will correspond to the marker's location
                popupAnchor: [0, -32],
              })}
              key={index}
              position={[data.location.lat, data.location.lng]}
              eventHandlers={{
                click: () =>
                  setSchoolPosition([data.location.lat, data.location.lng]),
              }}
            >
              <Popup closeButton={false}>
                <CustomPopup type={data.type} />
              </Popup>
            </Marker>
          ))}
          <div className="fixed bottom-7 left-5 z-[1000] flex gap-[1vw]">
            <div
              className="w-[6.5vw] h-[6.5vw] rounded-[0.6vw] cursor-pointer overflow-hidden border-4 shadow-md border-white"
              onClick={() => setIsSetMap(!isSetMap)}
            >
              <img src={mapType.img} alt="" />
            </div>
            {isSetMap && (
              <div className="bg-white rounded-[0.6vw] p-[0.7vw] flex gap-[0.8vw] shadow-md">
                {OSMapType.map((item, index) => (
                  <div
                    key={index}
                    className="wrapper cursor-pointer"
                    onClick={() => setMapType(item)}
                  >
                    <div className="w-[3.9vw] h-[3.9vw] rounded-[0.4vw] overflow-hidden border border-gray-300">
                      <img src={item.img} alt="" />
                    </div>
                    <p className="text-[0.7vw] text-center mt-1">{item.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
          <BottomControllerOSMap
            zoom={zoom}
            handleZoomIn={handleZoomIn}
            handleZoomOut={handleZoomOut}
          />
        </MapContainer>
      </div>
    </>
  );
}

export default OSMap;
