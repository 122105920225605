import React from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";
import BGSelectCategory from "../../../assets/images/select-pin-category.svg";
import BGSelectPin from "../../../assets/images/select-pin-environment.svg";
import { BreadCrumb, Switcher } from "../../../components";
import { envMenus } from "../../../utils/menus";
import GeoSearch from "./partials/OSMapGeoSearch";
import { OSMapEnvironment } from "./partials/OSMapEnvironment";
import { useParams } from "react-router-dom";

function CreateEnvironment() {
  const params = useParams();
  const [position, setPosition] = React.useState(null);
  const [searchMarker, setSearchMarker] = React.useState(null);
  const [activeCategory, setActiveCategory] = React.useState(null);
  const [isAIOn, setIsAIOn] = React.useState({
    vehicle_counting: false,
    people_detection: false,
    public_safety: false,
    resource_detection: false,
  });
  const [isPublish, setIsPublish] = React.useState(false);
  const [isDeviceStatus, setIsDeviceStatus] = React.useState(false);

  const handleSwitchToggle = (type) => {
    setIsAIOn((prevState) => ({
      ...prevState, // Preserve the previous state of each type
      [type]: !prevState[type], // Toggle the selected type
    }));
  };

  const MapClickHandler = ({ onClick, position }) => {
    useMapEvents({
      click(e) {
        onClick(e.latlng); // Call the onClick function passed from the parent
      },
    });

    return position === null ? null : <Marker position={position} />;
  };

  const handleMapClick = (newPosition) => {
    setPosition(newPosition);
    setSearchMarker(null); // Clear search marker on map click
  };

  const handleSearchResult = (newSearchMarker) => {
    setSearchMarker(newSearchMarker);
    setPosition(null); // Clear click position when search result is set
  };

  console.log(searchMarker);

  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <BreadCrumb
          pageName={"Environment Management"}
          type="configuration"
          subMenus={[
            {
              path: "/configuration/environment",
              name: "List Environment",
            },
            {
              path: `/configuration/environment/edit/${params.id}`,
              name: "Edit Environment",
            },
          ]}
        />
      </section>
      <section className="mt-[1.2vw] w-full bg-white rounded-[.8vw] pl-[1.5vw] py-[1.5vw] shadow-md flex flex-wrap gap-[2vw]">
        {/* map */}
        <div className="w-[30vw] h-[70vh] ">
          <div className="flex justify-between items-center">
            <div className="content">
              <h4 className="font-semibold text-[1.4vw]">Select Location</h4>
              <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                Select Environment Location
              </p>
            </div>
            {(position || searchMarker) && (
              <button
                onClick={() => {
                  setPosition(null);
                  setActiveCategory(null);
                  setSearchMarker(null);
                }}
                className="ripple bg-white border border-red-500 py-1.5 px-4 rounded-md text-[.8vw] text-red-500"
              >
                Remove Location
              </button>
            )}
          </div>
          <div className="h-[28.5vw]">
            <OSMapEnvironment
              clicker={
                <MapClickHandler onClick={handleMapClick} position={position} />
              }
              setSearchMarker={handleSearchResult}
              searchMarker={searchMarker}
              isView={true}
              position={position}
              isScroll={true}
              isDragging={true}
            />
          </div>
        </div>
        {/* select category */}
        {position && (
          <div className="w-[15vw]">
            <h4 className="font-semibold text-[1.4vw]">Category</h4>
            <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
              Select one of the environment categories
            </p>
            {envMenus.map((item, index) => (
              <button
                className={`ripple line-clamp-1 w-full py-3 text-left px-1.5 text-[#9C9C9C]
                ${
                  activeCategory === item.name
                    ? "bg-[#EDF1F5] text-black border-l-[.3vw] border-black font-semibold"
                    : ""
                }
                 flex items-center gap-2 text-[0.9vw] border-b-[1px]`}
                key={index}
                onClick={() => setActiveCategory(item.name)}
              >
                <item.icon className="w-[1.6vw] h-[1.6vw]" />
                {item.name}
              </button>
            ))}
            {/* <button
              className={`ripple line-clamp-1 w-full py-3 text-left px-1.5 text-[#9C9C9C]
                
                 flex items-center gap-2 text-[0.9vw] border-b-[1px]`}
            >
              <Ellipsis className="w-[1.4vw] h-[1.4vw]" />
              Another Environment
            </button> */}
          </div>
        )}
        {/* divider */}
        <div className="w-[.1vw] bg-[#CDCDCD]"></div>
        {/* right config */}
        <div className="flex-1">
          {position === null && (
            <>
              <div className="w-full h-full flex flex-col items-center justify-center gap-[1vw] pr-[1.5vw]">
                <img src={BGSelectPin} className="w-[20vw] h-[20vw] " />
                <p className="text-center font-medium text-[#BABABA] text-[1vw]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </p>
              </div>
            </>
          )}
          {position && !activeCategory && (
            <>
              <div className="w-full h-full flex flex-col items-center justify-center gap-[1vw] pr-[1.5vw]">
                <img src={BGSelectCategory} className="w-[20vw] h-[20vw] " />
                <p className="text-center font-medium text-[#BABABA] text-[1vw]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor
                </p>
              </div>
            </>
          )}
          {activeCategory && (
            <>
              <div className="h-[70vh] overflow-y-scroll pr-[1.5vw]">
                <div className="top-rightconfig flex justify-between items-center">
                  <div className="content">
                    <h4 className="font-semibold text-[1.4vw]">
                      Input Environment {activeCategory}
                    </h4>
                    <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                      Complete the data below
                    </p>
                  </div>
                  <div className="flex items-center gap-[.8vw]">
                    <p className="text-[.9vw]">Publish</p>
                    <Switcher
                      isOn={isPublish}
                      handleToggle={() => setIsPublish(!isPublish)}
                    />
                  </div>
                </div>
                <div className="mt-[1.4vw]">
                  <div className="location bg-[#F3F7FF] p-[.6vw] rounded-[.4vw] border border-[#2563EB]">
                    <h4 className="font-bold text-[1vw]">Location</h4>
                    <p className="text-[.8vw]">
                      Latitude {position?.lat} Longitude {position?.lng}
                    </p>
                    <p className="text-[.8vw]">Jalan Batu Ampar No 16</p>
                  </div>
                </div>
                <div className="mt-[1.4vw]">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="pb-[1.4vw]">
                      <div className="form-group">
                        <label
                          htmlFor=""
                          className="text-[#4D4D4D] text-[1vw] font-semibold"
                        >
                          Environment Name
                        </label>
                        <input
                          type="text"
                          className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                          placeholder="Input CCTV Name"
                        />
                      </div>
                      <div className="form-group mt-[1.2vw]">
                        <label
                          htmlFor=""
                          className="text-[#4D4D4D] text-[1vw] font-semibold"
                        >
                          Source
                        </label>
                        <input
                          type="text"
                          className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                          placeholder="Input CCTV Source"
                        />
                      </div>
                      <div className="form-group mt-[1.2vw]">
                        <label
                          htmlFor=""
                          className="text-[#4D4D4D] text-[1vw] font-semibold"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                          placeholder="Input Description"
                        />
                      </div>
                      {/* surveilence ai */}
                      <h4 className="font-semibold text-[1.4vw] mt-[1.4vw]">
                        Surveilence AI
                      </h4>
                      <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                        Enable AI Feature
                      </p>
                      <div className="mt-[1.2vw]">
                        <div className="flex items-center gap-[.8vw]">
                          <div className="self-start mt-2">
                            <Switcher
                              isOn={isAIOn.vehicle_counting}
                              handleToggle={() =>
                                handleSwitchToggle("vehicle_counting")
                              }
                            />
                          </div>
                          <div className="w-full">
                            <div className="content">
                              <h4 className="text-[#4D4D4D] text-[1vw]">
                                Vehicle Counting
                              </h4>
                              <p className="text-[.6vw] text-[#7A7A7A]">
                                Detect vehicle type, counting and speed average
                              </p>
                            </div>
                            {isAIOn.vehicle_counting && (
                              <input
                                type="text"
                                className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-[.8vw] mt-[1vw]">
                          <div className="self-start mt-2">
                            <Switcher
                              isOn={isAIOn.people_detection}
                              handleToggle={() =>
                                handleSwitchToggle("people_detection")
                              }
                            />
                          </div>
                          <div className="w-full">
                            <div className="content">
                              <h4 className="text-[#4D4D4D] text-[1vw]">
                                People Detection
                              </h4>
                              <p className="text-[.6vw] text-[#7A7A7A]">
                                Detect vehicle type, counting and speed average
                              </p>
                            </div>
                            {isAIOn.people_detection && (
                              <input
                                type="text"
                                className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-[.8vw] mt-[1vw]">
                          <div className="self-start mt-2">
                            <Switcher
                              isOn={isAIOn.public_safety}
                              handleToggle={() =>
                                handleSwitchToggle("public_safety")
                              }
                            />
                          </div>
                          <div className="w-full">
                            <div className="content">
                              <h4 className="text-[#4D4D4D] text-[1vw]">
                                Public Safety
                              </h4>
                              <p className="text-[.6vw] text-[#7A7A7A]">
                                Detect vehicle type, counting and speed average
                              </p>
                            </div>
                            {isAIOn.public_safety && (
                              <input
                                type="text"
                                className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-[.8vw] mt-[1vw]">
                          <div className="self-start mt-2">
                            <Switcher
                              isOn={isAIOn.resource_detection}
                              handleToggle={() =>
                                handleSwitchToggle("resource_detection")
                              }
                            />
                          </div>
                          <div className="w-full">
                            <div className="content">
                              <h4 className="text-[#4D4D4D] text-[1vw]">
                                Resource Detection
                              </h4>
                              <p className="text-[.6vw] text-[#7A7A7A]">
                                Detect vehicle type, counting and speed average
                              </p>
                            </div>
                            {isAIOn.resource_detection && (
                              <input
                                type="text"
                                className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] focus:outline-sky-500 "
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      {/* device status */}
                      <h4 className="font-semibold text-[1.4vw] mt-[1.4vw]">
                        Device Status
                      </h4>
                      <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                        Set on/off device
                      </p>
                      <div className="flex items-center gap-[.8vw] mt-[1.4vw]">
                        <Switcher
                          isOn={isDeviceStatus}
                          handleToggle={() =>
                            setIsDeviceStatus(!isDeviceStatus)
                          }
                        />
                        <div className="content">
                          <h4 className="text-[#4D4D4D] text-[1vw]">On</h4>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="buttons flex gap-[1.5vw] mt-[2vw]">
                      <button className="ripple w-1/2 text-[#E62525] bg-white border border-[#E62525] rounded-[.5vw] py-[.5vw]">
                        Cancel
                      </button>
                      <button className="ripple w-1/2 text-white bg-[#2563EB] rounded-[.5vw] py-[.5vw]">
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default CreateEnvironment;
