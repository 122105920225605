import React from "react";
import { BreadCrumb } from "../../../components";
import { EmbedSuperset } from "../../../utils";

function SalesDashboard() {
  const containerRef = React.useRef(null);
  const supersetID = "3934f985-bb78-4662-b8c5-7ba90255a394";

  React.useEffect(() => {
    EmbedSuperset(containerRef, supersetID);
  }, []);

  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <BreadCrumb pageName={"Sales Dashboard"} type={"dashboard"} />
      </section>
      <div className="mt-[0.8vw] p-[1vw] bg-white shadow-md rounded-[.8vw]">
        <div ref={containerRef} className="embedded w-full h-[79vh]" />
      </div>
    </>
  );
}

export default SalesDashboard;
