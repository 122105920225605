import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import { OpenStreetMapProvider, GeoSearchControl } from "leaflet-geosearch";
import L from "leaflet";
import "leaflet-geosearch/dist/geosearch.css";

const GeoSearch = ({ setSearchMarker, searchMarker }) => {
  const map = useMap();

  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      style: "button",
      showMarker: false, // Disable the default marker
      retainZoomLevel: true,
      animateZoom: true,
      autoClose: true,
      keepResult: true,
    });

    map.addControl(searchControl);

    // Listen for search result selection
    map.on("geosearch/showlocation", (result) => {
      const { x, y } = result.location;
      setSearchMarker([y, x]); // Set the search marker position
    });

    // Optionally listen for search clear events
    map.on("geosearch/clearlocation", () => {
      setSearchMarker(null); // Clear the marker when the location is cleared
    });

    return () => {
      map.removeControl(searchControl);
      map.off("geosearch/showlocation");
      map.off("geosearch/clearlocation");
    };
  }, [map]);

  return null;
};

export default GeoSearch;
