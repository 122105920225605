import React from "react";
import { Outlet } from "react-router-dom";
import { DashboardNavbar, LoadPage, SidebarDashboard } from "../components";
import { useSelector } from "react-redux";

function DashboardLayout() {
  const sidebarState = useSelector((state) => state.navbar.show);
  return (
    <div className="flex w-full min-h-screen">
      <div
        className={`${
          sidebarState ? "w-[17vw]" : ""
        } transition-all block bg-white border-r-2`}
      >
        <div
          className={`fixed block ${
            sidebarState ? "w-[17vw]" : ""
          } transition-all top-[4vw] left-0`}
        >
          <SidebarDashboard />
        </div>
      </div>
      <div className="flex-1 flex flex-col relative">
        <DashboardNavbar />
        <main className="bg-[#F8FAFC] h-full px-[1vw] pt-[3.5vw]">
          <LoadPage>
            <Outlet />
          </LoadPage>
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
{
  /* <div className="wrapper-logo h-[75px] border-b-2 p-3 flex justify-center">
            <div className="flex items-center gap-2">
              <img
                src="https://assets.skota.id/dev.superapps/images/logo-skota.png"
                width={50}
                height={50}
              />
              <div className="">
                <h4 className="font-roboto font-bold text-xl">SKOTA</h4>
                <p className="text-sm">Ecosystem</p>
              </div>
            </div>
          </div> */
}
