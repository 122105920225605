import AnalyticIcon from "../assets/icons/analytic.svg?react";
import CameraVideoIcon from "../assets/icons/camera-video.svg?react";
import MoneyBagIcon from "../assets/icons/money-bag.svg?react";
import SmartWatchIcon from "../assets/icons/smart-watch.svg?react";
import DashboardIcon from "../assets/icons/dashboard.svg?react";
import CloudIcon from "../assets/icons/cloud.svg?react";
import SettingIcon from "../assets/icons/setting.svg?react";
import { LocateOff, MapIcon, MapPin } from "lucide-react";
import POI from "./dummy/POI.json";
import CCTVIcon from "../assets/icons/sidemaps/cctv.svg?react";
import AirportIcon from "../assets/icons/sidemaps/airport.svg?react";
import ATMIcon from "../assets/icons/sidemaps/atm.svg?react";
import BankIcon from "../assets/icons/sidemaps/bank.svg?react";
import BusStationIcon from "../assets/icons/sidemaps/bus-station.svg?react";
import ColleagueIcon from "../assets/icons/sidemaps/colleague.svg?react";
import DestinationIcon from "../assets/icons/sidemaps/destination.svg?react";
import GasStationIcon from "../assets/icons/sidemaps/gas-station.svg?react";
import Hospital2Icon from "../assets/icons/sidemaps/hospital2.svg?react";
import HospitalIcon from "../assets/icons/sidemaps/hospital.svg?react";
import MotelIcon from "../assets/icons/sidemaps/motel.svg?react";
import MuseumIcon from "../assets/icons/sidemaps/museum.svg?react";
import ParkIcon from "../assets/icons/sidemaps/park.svg?react";
import PharmacyIcon from "../assets/icons/sidemaps/pharmacy.svg?react";
import PublicLampIcon from "../assets/icons/sidemaps/public-lamp.svg?react";
import SchoolIcon from "../assets/icons/sidemaps/school.svg?react";
import TicketIcon from "../assets/icons/sidemaps/ticket.svg?react";
import TourIcon from "../assets/icons/sidemaps/tour.svg?react";
import TrafficLightIcon from "../assets/icons/sidemaps/traffic-light.svg?react";
import waterSensorIcon from "../assets/icons/sidemaps/water-sensor.svg?react";
import WorshipIcon from "../assets/icons/sidemaps/worship.svg?react";
//
import SkotaEcosystem from "../assets/icons/skota/skota-ecosystem.svg?react";
import SkotaSuperapps from "../assets/icons/skota/skota-superapps.svg?react";
import SkotaData from "../assets/icons/skota/skota-data.svg?react";
import SkotaSurveilence from "../assets/icons/skota/skota-surveilence.svg?react";
import SkotaThinksboard from "../assets/icons/skota/skota-thinksboard.svg?react";
import SkotaDocumentAnalyzer from "../assets/icons/skota/skota-document-analyzer.svg?react";

export const sideMenusDashboard = [
  {
    icon: DashboardIcon,
    name: "Dashboard",
    path: "/dashboard",
  },
  {
    icon: CameraVideoIcon,
    name: "CCTV List",
    path: "/dashboard/cctv",
  },
  // {
  //   icon: AnalyticIcon,
  //   name: "Media Analytics",
  //   path: "/dashboard/media",
  // },
  {
    icon: MoneyBagIcon,
    name: "Sales Dashboard",
    path: "/dashboard/sales",
  },
  {
    icon: SmartWatchIcon,
    name: "IOT",
    path: "/dashboard/iot",
  },
  // {
  //   icon: CloudIcon,
  //   name: "FTC Cloud",
  //   path: "/dashboard/ftc",
  // },
];

export const sideMenusConfiguration = [
  {
    icon: null,
    name: "Users",
    path: "/configuration/users",
  },
  {
    icon: null,
    name: "Roles",
    path: "/configuration/roles",
  },
  {
    icon: null,
    name: "Category Management",
    path: "/configuration/category",
  },
  {
    icon: null,
    name: "Homepage Management",
    path: "/configuration/homepage",
  },
  {
    icon: null,
    name: "POI Management",
    path: "/configuration/poi",
  },
  {
    icon: null,
    name: "Environment Management",
    path: "/configuration/environment",
  },
  {
    icon: null,
    name: "Dashboard Management",
    path: "/configuration/dashboard",
  },
  {
    icon: null,
    name: "Data Source Management",
    path: "/configuration/data-source",
  },
];
export const navMenus = [
  {
    name: "GEOVIEW",
    pathname: "/map",
    icon: MapIcon,
  },
  {
    name: "DASHBOARD",
    pathname: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: "CONFIGURATION",
    pathname: "/configuration/users",
    icon: SettingIcon,
  },
];

export const mapMenus = [
  {
    // icon: MapPin,
    name: "Borderland",
    path: "/",
    sub: POI.data,
  },
  {
    // icon: MapIcon,
    name: "Environment",
    path: "/",
    sub: [
      {
        category: "OSMap",
      },
      {
        category: "GMap",
      },
    ],
  },
  {
    // icon: MapPin,
    name: "Fasilitas Umum",
    path: "/",
    sub: POI.data,
  },
  {
    // icon: MapIcon,
    name: "Pendidikan",
    path: "/",
    sub: [
      {
        category: "OSMap",
      },
      {
        category: "GMap",
      },
    ],
  },
  {
    // icon: LocateOff,
    name: "Aktivitas",
    path: "/",
  },
  {
    // icon: LocateOff,
    name: "Penginapan",
    path: "/",
  },
  // {
  //   icon: null,
  //   name: "Geoview",
  //   path: "/",
  // },
  // {
  //   icon: null,
  //   name: "Dashboard",
  //   path: "/dashboard",
  // },
  // {
  //   icon: null,
  //   name: "Configuration",
  //   path: "/configuration",
  // },
];

export const skotaIcons = [
  {
    name: "SKOTA Ecosystem",
    icon: SkotaEcosystem,
    isAnimate: false,
    bg: "bg-[#1B8033]",
  },
  {
    name: "SKOTA Superapps",
    icon: SkotaSuperapps,
    isAnimate: false,
    bg: "bg-[#25244C]",
  },
  {
    name: "SKOTA Data",
    icon: SkotaData,
    isAnimate: false,
    bg: "bg-[#88919C]",
  },
  {
    name: "SKOTA Surveilence",
    icon: SkotaSurveilence,
    isAnimate: false,
    bg: "bg-[#2563EB]",
  },
  {
    name: "SKOTA Thinksboard",
    icon: SkotaThinksboard,
    isAnimate: false,
    bg: "bg-[#D2098E]",
  },
  {
    name: "SKOTA Document Analyzer",
    icon: SkotaDocumentAnalyzer,
    isAnimate: false,
    bg: "bg-[#F7C850]",
  },
];

export const borderLandMenus = ["Provinsi", "Kota", "Kecamatan", "Kabupaten"];
export const envMenus = [
  {
    name: "CCTV",
    icon: CCTVIcon,
    isAnimate: false,
  },
  {
    name: "Traffic Light",
    icon: TrafficLightIcon,
    isAnimate: true,
  },
  {
    name: "Water Sensor",
    icon: waterSensorIcon,
    isAnimate: true,
  },
  {
    name: "Public Lamp",
    icon: PublicLampIcon,
    isAnimate: true,
  },
];
export const publicFacilityMenus = [
  {
    name: "Terminal",
    icon: BusStationIcon,
    isAnimate: false,
  },
  {
    name: "Bandara",
    icon: AirportIcon,
    isAnimate: false,
  },
  {
    name: "SPBU",
    icon: GasStationIcon,
    isAnimate: false,
  },
  {
    name: "ATM",
    icon: ATMIcon,
    isAnimate: false,
  },
  {
    name: "Bank",
    icon: BankIcon,
    isAnimate: false,
  },
  {
    name: "Tempat Ibadah",
    icon: WorshipIcon,
    isAnimate: true,
  },
  {
    name: "Rumah Sakit",
    icon: HospitalIcon,
    isAnimate: true,
  },
  {
    name: "Apotek",
    icon: PharmacyIcon,
  },
];
export const eduMenus = [
  {
    name: "SD",
    icon: SchoolIcon,
    isAnimate: false,
  },
  {
    name: "SMP",
    icon: SchoolIcon,
    isAnimate: false,
  },
  {
    name: "SMA",
    icon: SchoolIcon,
    isAnimate: false,
  },
  {
    name: "Perguruan Tinggi",
    icon: ColleagueIcon,
    isAnimate: true,
  },
];
export const actMenus = [
  {
    name: "Museum",
    icon: MuseumIcon,
    isAnimate: true,
  },
  {
    name: "Taman",
    icon: ParkIcon,
    isAnimate: false,
  },
  {
    name: "Destinasi",
    icon: DestinationIcon,
    isAnimate: true,
  },
  {
    name: "Paket Wisata",
    icon: TourIcon,
    isAnimate: true,
  },
  {
    name: "Events",
    icon: TicketIcon,
    isAnimate: false,
  },
];
export const lodgMenus = [
  {
    name: "Motel",
    icon: MotelIcon,
    isAnimate: false,
  },
  {
    name: "Hotel",
    icon: Hospital2Icon,
    isAnimate: false,
  },
];
export const poiMenus = [
  "CCTV",
  "Traffic Light",
  "Water Sensor",
  "Public Lamp",
  "Terminal",
  "Bandara",
  "SPBU",
  "ATM",
  "Bank",
  "Tempat Ibadah",
  "Rumah Sakit",
  "Apotek",
  "SD",
  "SMP",
  "SMA",
  "Perguruan Tinggi",
  "Museum",
  "Taman",
  "Destinasi",
  "Paket Wisata",
  "Events",
  "Hotel",
  "Motel",
];
export const poiMenusDashboard = [
  {
    name: "Fasilitas Umum",
    icon: BusStationIcon,
    sum: publicFacilityMenus.length,
  },
  {
    name: "Pendidikan",
    icon: ColleagueIcon,
    sum: eduMenus.length,
  },
  {
    name: "Environment",
    icon: waterSensorIcon,
    sum: envMenus.length,
  },
  {
    name: "Aktivitas",
    icon: MuseumIcon,
    sum: actMenus.length,
  },
  {
    name: "Penginapan",
    icon: Hospital2Icon,
    sum: lodgMenus.length,
  },
];
