import {
  Bell,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Cloud,
  EllipsisVertical,
  MapIcon,
  MapPin,
  Menu,
  Search,
  X,
} from "lucide-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "../../../assets/icons/dashboard.svg?react";
import SettingIcon from "../../../assets/icons/setting.svg?react";
import WeatherIcon from "../../../assets/icons/weather-icon.svg?react";
import { MapPerson, ProjectMap, SearchResult } from "../../../assets/images";
import { setGeoType, setMapPinTypes } from "../../../redux/slices/MapSlice";
import {
  actMenus,
  eduMenus,
  envMenus,
  lodgMenus,
  poiMenus,
  publicFacilityMenus,
} from "../../../utils/menus";
import POIButton from "./POIButton";
import { setLogout } from "../../../redux/slices/AuthSlice";
import { Switcher } from "../../../components";

function TopController() {
  const mapPins = useSelector((state) => state.map.mapPinTypes);
  const dispatch = useDispatch();
  const [isMenu, setIsMenu] = React.useState(false);
  const [isProfile, setIsProfile] = React.useState(false);
  const [isWeather, setIsWeather] = React.useState(false);
  const navigate = useNavigate();
  const [isSwitchOn, setIsSwitchOn] = React.useState({
    provinsi: false,
    kota: false,
    kabupaten: false,
    kecamatan: false,
  });
  const [isPinSwitchOn, setIsPinSwitchOn] = React.useState({
    poi: false,
    env: false,
    pf: false,
    edu: false,
    act: false,
    lodg: false,
  });
  const [searchField, setSearchField] = React.useState("");
  const [activeProject, setActiveProject] = React.useState(null);

  const handleSwitchToggle = (type) => {
    if (!isSwitchOn[type]) {
      dispatch(setGeoType(type));
      setIsSwitchOn(() => ({
        [type]: true,
      }));
    } else {
      dispatch(setGeoType(""));
      setIsSwitchOn(() => ({
        [type]: false,
      }));
    }
  };

  // single button click
  function handleFilter(menu) {
    if (mapPins.includes(menu)) {
      return dispatch(setMapPinTypes(mapPins.filter((item) => item !== menu)));
    } else {
      return dispatch(setMapPinTypes([...mapPins, menu]));
    }
  }

  // switch
  function handlePinSwitch(type) {
    const menusMapping = {
      poi: poiMenus,
      env: envMenus,
      pf: publicFacilityMenus,
      edu: eduMenus,
      act: actMenus,
      lodg: lodgMenus,
    };

    const toggleAllPins = (isOn) => {
      const newPinSwitchState = {
        poi: isOn,
        env: isOn,
        pf: isOn,
        edu: isOn,
        act: isOn,
        lodg: isOn,
      };
      setIsPinSwitchOn(newPinSwitchState);
      dispatch(setMapPinTypes(isOn ? poiMenus : []));
    };

    const updatePins = (type, menu) => {
      setIsPinSwitchOn((prevState) => ({
        ...prevState,
        [type]: !prevState[type],
      }));

      if (!isPinSwitchOn[type]) {
        const newPins =
          mapPins.length > 0
            ? [...mapPins, ...menu.map((item) => item.name)]
            : menu.map((item) => item.name);
        dispatch(setMapPinTypes(newPins));
      } else {
        const filteredPins = mapPins.filter(
          (value) => !menu.some((obj) => obj.name === value)
        );
        dispatch(setMapPinTypes(filteredPins));
      }
    };

    if (type === "poi") {
      toggleAllPins(!isPinSwitchOn[type]);
    } else if (menusMapping[type]) {
      updatePins(type, menusMapping[type]);
    }
  }

  React.useEffect(() => {
    const allEnvMenusInMapPins = envMenus.every((item) =>
      mapPins.includes(item.name)
    );
    const allPublicFacilityInMapPins = publicFacilityMenus.every((item) =>
      mapPins.includes(item.name)
    );
    const allEduMenusInMapPins = eduMenus.every((item) =>
      mapPins.includes(item.name)
    );
    const allActMenusInMapPins = actMenus.every((item) =>
      mapPins.includes(item.name)
    );
    const allLodgInMapPins = lodgMenus.every((item) =>
      mapPins.includes(item.name)
    );

    // Update both env and pf in a single state update
    setIsPinSwitchOn((prevState) => ({
      ...prevState,
      poi:
        allEnvMenusInMapPins &&
        allPublicFacilityInMapPins &&
        allEduMenusInMapPins &&
        allActMenusInMapPins &&
        allLodgInMapPins,
      env: allEnvMenusInMapPins,
      pf: allPublicFacilityInMapPins,
      edu: allEduMenusInMapPins,
      act: allActMenusInMapPins,
      lodg: allLodgInMapPins,
    }));
  }, [mapPins, isPinSwitchOn.env]);

  function handleLogout() {
    dispatch(setLogout());
    navigate("/");
  }

  return (
    <>
      {/* {isMenu && (
        <div
          onClick={() => setIsMenu(false)}
          className={`fixed w-full min-h-screen transition-all duration-300 bg-black bg-opacity-50 top-0 left-0 z-10`}
        ></div>
      )} */}
      <div
        className={`top-nav sticky left-0 top-0 flex items-center justify-between z-10 ${
          !isMenu && "p-[1.6vw]"
        }`}
      >
        {/* left nav */}
        <div className="flex items-center gap-[0.8vw]">
          <div
            className={`left-nav transition-all duration-300  ${
              isMenu
                ? "min-h-screen bg-white fixed top-0 left-0 z-[11] w-[28vw]"
                : "card !py-[0.4vw] w-[15.4vw]"
            }`}
          >
            {/* header */}
            <div
              className={`flex gap-[0.4vw] items-center ${
                isMenu && "border-b-[0.1vw] pb-[0.8vw] !p-[1.6vw] w-full"
              }`}
            >
              <div className="flex gap-[0.4vw] items-center w-full">
                {isMenu && (
                  <X
                    className="cursor-pointer w-[1.2vw] h-[1.2vw]"
                    onClick={() => setIsMenu(false)}
                  />
                )}
                {!isMenu && (
                  <Menu
                    className="cursor-pointer w-[1vw] h-[1vw]"
                    onClick={() => setIsMenu(true)}
                  />
                )}
                {!isMenu && (
                  <div className="search flex items-center relative rounded-[0.4vw] w-full">
                    <img
                      src="https://assets.skota.id/dev.superapps/images/logo-skota.png"
                      className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-[0.1vw] w-[1.2vw] h-[1.2vw]"
                    />
                    <input
                      placeholder="SCP Pemprov Jakarta"
                      type="text"
                      className=" text-[0.8vw] rounded-[0.4vw] py-[0.2vw] pl-[1.5vw] pr-[1.5vw] font-bold placeholder-black focus:outline-blue-200 w-full"
                    />
                    <Search className="cursor-pointer pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-[0.2vw] w-[1vw] h-[1vw]" />
                  </div>
                )}
                {isMenu && (
                  <div className="search flex gap-[0.3vw] items-center relative rounded-md w-full">
                    <img
                      src="https://assets.skota.id/dev.superapps/images/logo-skota.png"
                      className="pointer-events-none w-[3.2vw] h-[3.2vw]"
                    />
                    <div className="">
                      <h5 className="font-bold text-[1.4vw]">SKOTA</h5>
                      <p className="-mt-[0.2vw] text-[1vw]">Ecosystem</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* searching */}
            {isMenu && (
              <div className="!p-[1.6vw] relative">
                <input
                  placeholder="Search"
                  type="text"
                  className=" text-[1vw] text-[#7B7B7B] py-[0.8vw] pl-[1vw] pr-[1.5vw] bg-white placeholder-[#7B7B7B] focus:outline-blue-200 w-full border rounded-[0.4vw] relative shadow-sm"
                  onChange={(e) => setSearchField(e.target.value)}
                  defaultValue={searchField}
                />
                <Search className="cursor-pointer pointer-events-none absolute top-1/2 transform -translate-y-1/2 right-[2.5vw] w-[1.2vw] h-[1.2vw]" />
              </div>
            )}
            {/* side menus */}
            {isMenu && searchField.length > 0 && (
              <>
                <div className="!px-[1.6vw] flex flex-col gap-[0.8vw] h-[34vw] overflow-y-scroll overflow-x-hidden">
                  <h4 className="font-bold text-[1.4vw]">Search Result</h4>
                  <div className="flex flex-col gap-[2vw]">
                    {[1, 2, 3, 4, 5].map((item) => (
                      <div
                        key={item}
                        className={`flex gap-[0.8vw] pb-[1.5vw] ${
                          item !== 5 ? "border-b-[0.2vw] border-dashed" : ""
                        }`}
                      >
                        <div className="w-[7.6vw] h-[6.6vw] rounded-[0.35vw] overflow-hidden">
                          <img
                            src={SearchResult}
                            alt=""
                            className="w-full h-full"
                          />
                        </div>
                        <div className="flex-1">
                          <h5 className="font-bold text-[1vw] line-clamp-2">
                            Sekolah Tinggi Manajemen Asuransi Trisakti
                          </h5>
                          <p className="text-[#635F5F] text-[0.8vw] line-clamp-3">
                            Jl. Jenderal Ahmad Yani No.85, RT.15/RW.7, Kayu
                            Putih, Kec. Pulo Gadung, Kota Jakarta Timur, Daerah
                            Khusus Ibukota Jakarta 13210
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
            {isMenu && searchField.length === 0 && (
              <div className="!px-[1.6vw] flex flex-col gap-[0.8vw] h-[34vw] overflow-y-scroll overflow-x-hidden">
                <div className="bg-[#F8FAFC] rounded-[0.9vw] p-[1.6vw]">
                  <h4 className="font-bold text-[1.2vw]">Borderland</h4>
                  <div className="mt-[1.2vw] flex flex-col gap-[0.9vw]">
                    <div className="w-full flex justify-between items-center border-b-2 border-dashed pb-3">
                      <p className="text-[0.9vw] text-[#635F5F]">Provinsi</p>
                      <Switcher
                        isOn={isSwitchOn.provinsi}
                        handleToggle={() => handleSwitchToggle("provinsi")}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center border-b-2 border-dashed pb-3">
                      <p className="text-[0.9vw] text-[#635F5F]">Kota</p>
                      <Switcher
                        isOn={isSwitchOn.kota}
                        handleToggle={() => handleSwitchToggle("kota")}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center border-b-2 border-dashed pb-3">
                      <p className="text-[0.9vw] text-[#635F5F]">Kecamatan</p>
                      <Switcher
                        isOn={isSwitchOn.kecamatan}
                        handleToggle={() => handleSwitchToggle("kecamatan")}
                      />
                    </div>
                    <div className="w-full flex justify-between items-center">
                      <p className="text-[0.9vw] text-[#635F5F]">Kabupaten</p>
                      <Switcher
                        isOn={isSwitchOn.kabupaten}
                        handleToggle={() => handleSwitchToggle("kabupaten")}
                      />
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="bg-[#F8FAFC] rounded-[0.9vw] p-[1.6vw]">
                  <div className="flex items-center justify-between">
                    <h4 className="font-bold text-[1.2vw]">Projects</h4>
                    <p className="text-[#2563EB] text-[0.8vw] hover:underline cursor-pointer">
                      More
                    </p>
                  </div>
                  <div className="grid grid-cols-3 mt-[1.2vw] gap-[1.2vw]">
                    {[1, 2, 3, 4, 5, 6].map((item) => (
                      <div className="col-span-1" key={item}>
                        <div className="w-[6.7vw]">
                          <img
                            src={ProjectMap}
                            alt=""
                            className={`rounded-[0.4vw] border-[0.12vw] ${
                              activeProject === item ? "border-blue-900" : ""
                            } cursor-pointer`}
                            onClick={() => {
                              activeProject === item
                                ? setActiveProject(null)
                                : setActiveProject(item);
                            }}
                          />
                          <p
                            className={`text-[0.8vw] mt-[0.4vw] ${
                              activeProject === item ? "text-blue-900" : ""
                            }`}
                          >
                            Map Persebaran Pertamina
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/*  */}
                <div className="bg-[#F8FAFC] rounded-[0.9vw] p-[1.6vw]">
                  <div className="flex justify-between items-center">
                    <h4 className="font-bold text-[1.4vw]">
                      Point of Interest
                    </h4>
                    <Switcher
                      isOn={isPinSwitchOn.poi}
                      handleToggle={() => handlePinSwitch("poi")}
                    />
                  </div>
                  <div className="mt-[2.8vw] border-b-2 border-dashed pb-4">
                    <div className="flex justify-between items-center">
                      <h4 className="font-bold text-[1.2vw]">Environment</h4>
                      <Switcher
                        isOn={isPinSwitchOn.env}
                        handleToggle={() => handlePinSwitch("env")}
                      />
                    </div>
                    <div className="grid grid-cols-5 gap-x-[0.5vw] gap-y-[1vw] mt-[1.2vw]">
                      <POIButton
                        data={envMenus}
                        pinData={mapPins}
                        handleFilter={handleFilter}
                      />
                    </div>
                  </div>
                  <div className="mt-[1.4vw] border-b-2 border-dashed pb-4">
                    <div className="flex justify-between items-center">
                      <h4 className="font-bold text-[1.2vw]">Fasilitas Umum</h4>
                      <Switcher
                        isOn={isPinSwitchOn.pf}
                        handleToggle={() => handlePinSwitch("pf")}
                      />
                    </div>
                    <div className="grid grid-cols-5 gap-x-[0.5vw] gap-y-[1vw] mt-[1.2vw]">
                      <POIButton
                        data={publicFacilityMenus}
                        pinData={mapPins}
                        handleFilter={handleFilter}
                      />
                    </div>
                  </div>
                  <div className="mt-[1.4vw] border-b-2 border-dashed pb-4">
                    <div className="flex justify-between items-center">
                      <h4 className="font-bold text-[1.2vw]">Pendidikan</h4>
                      <Switcher
                        isOn={isPinSwitchOn.edu}
                        handleToggle={() => handlePinSwitch("edu")}
                      />
                    </div>
                    <div className="grid grid-cols-5 gap-x-[0.5vw] gap-y-[1vw] mt-[1.2vw]">
                      <POIButton
                        data={eduMenus}
                        pinData={mapPins}
                        handleFilter={handleFilter}
                      />
                    </div>
                  </div>
                  <div className="mt-[1.4vw] border-b-2 border-dashed pb-4">
                    <div className="flex justify-between items-center">
                      <h4 className="font-bold text-[1.2vw]">Aktivitas</h4>
                      <Switcher
                        isOn={isPinSwitchOn.act}
                        handleToggle={() => handlePinSwitch("act")}
                      />
                    </div>
                    <div className="grid grid-cols-5 gap-x-[0.5vw] gap-y-[1vw] mt-[1.2vw]">
                      <POIButton
                        data={actMenus}
                        pinData={mapPins}
                        handleFilter={handleFilter}
                      />
                    </div>
                  </div>
                  <div className="mt-[1.4vw]">
                    <div className="flex justify-between items-center">
                      <h4 className="font-bold text-[1.2vw]">Penginapan</h4>
                      <Switcher
                        isOn={isPinSwitchOn.lodg}
                        handleToggle={() => handlePinSwitch("lodg")}
                      />
                    </div>
                    <div className="grid grid-cols-5 gap-x-[0.5vw] gap-y-[1vw] mt-[1.2vw]">
                      <POIButton
                        data={lodgMenus}
                        pinData={mapPins}
                        handleFilter={handleFilter}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* maps menu hover */}
          {!isMenu && (
            <div className="flex gap-[0.8vw]">
              <button
                onClick={() => navigate("/map")}
                className="ripple bg-white shadow-md rounded-full px-[1.2vw] py-[0.4vw] text-[1vw] flex items-center gap-[0.5vw]"
              >
                <MapIcon
                  className="w-[0.9vw] h-[0.9vw] mb-[2px]"
                  stroke="#9C9C9C"
                />
                <p className="text-[0.9vw]">GEOVIEW</p>
              </button>
              <button
                onClick={() => navigate("/dashboard")}
                className="ripple bg-white shadow-md rounded-full px-[1.2vw] py-[0.4vw] text-[1vw] flex items-center gap-[0.5vw]"
              >
                <DashboardIcon className="w-[0.9vw] h-[0.9vw] mb-[2px]" />
                <p className="text-[0.9vw]">DASHBOARD</p>
              </button>
              <button
                onClick={() => navigate("/configuration")}
                className="ripple bg-white shadow-md rounded-full px-[1.2vw] py-[0.4vw] text-[1vw] flex items-center gap-[0.5vw]"
              >
                <SettingIcon className="w-[0.9vw] h-[0.9vw] mb-[2px]" />
                <p className="text-[0.9vw]">CONFIGURATION</p>
              </button>
            </div>
          )}
        </div>
        {/* right nav */}
        <div
          className={`right-nav relative self-start hidden lg:block ${
            isMenu && "!hidden p-[1.6vw]"
          }`}
        >
          {/* weather widget */}
          <div
            className={`absolute bg-white shadow-md min-w-[28vw] -right-[0vw] top-[2.7vw] rounded-[0.9vw] p-[1vw] opacity-0 transition-all duration-300 ${
              isWeather ? "opacity-100 block" : "hidden"
            }`}
          >
            <div className="flex w-full items-center justify-between pb-[0.4vw] border-b-[0.1vw]">
              <p className="text-[1.2vw]">Weather</p>
              <EllipsisVertical className="w-[1vw] h-[1vw]" />
            </div>
            <div className="mt-[1vw] grid grid-cols-2">
              <div className="col-span-1 flex flex-col items-center cursor-pointer justify-center">
                <h5 className="font-semibold text-[1.2vw]">08.21 WIB</h5>
                <WeatherIcon className="w-[7vw] h-[7vw]" />
                <p className="text-[1vw]">Cloudy Sunny, 24°C</p>
              </div>
              <div className="col-span-1 flex flex-col items-center cursor-pointer">
                <div className="top flex items-center justify-between">
                  <p className="text-[0.8vw]">Monday, 22 August 2022</p>
                  <div className="flex items-center gap-[0.1vw]">
                    <button className="ripple p-[0.2vw] rounded-[0.3vw]">
                      <ChevronLeft className="w-[1.2vw] h-[1.2vw]" />
                    </button>
                    <button className="ripple p-[0.2vw] rounded-[0.3vw]">
                      <ChevronRight className="w-[1.2vw] h-[1.2vw]" />
                    </button>
                  </div>
                </div>
                <div className="bottom">
                  <ul className="list-disc pl-[0.9vw] mt-[0.4vw]">
                    <li className="text-[0.8vw] mt-[0.6vw]">
                      <div className="flex items-center justify-between">
                        <p>10:00 WIB</p>
                        <p>Light Rain, 27°C</p>
                      </div>
                    </li>
                    <li className="text-[0.8vw] mt-[0.6vw]">
                      <div className="flex items-center justify-between">
                        <p>10:00 WIB</p>
                        <p>Light Rain, 27°C</p>
                      </div>
                    </li>
                    <li className="text-[0.8vw] mt-[0.6vw]">
                      <div className="flex items-center justify-between">
                        <p>10:00 WIB</p>
                        <p>Light Rain, 27°C</p>
                      </div>
                    </li>
                    <li className="text-[0.8vw] mt-[0.6vw]">
                      <div className="flex items-center justify-between">
                        <p>10:00 WIB</p>
                        <p>Light Rain, 27°C</p>
                      </div>
                    </li>
                    <li className="text-[0.8vw] mt-[0.6vw]">
                      <div className="flex items-center justify-between">
                        <p>10:00 WIB</p>
                        <p>Light Rain, 27°C</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* profile widget */}
          <div
            className={`absolute bg-white shadow-md min-w-[15vw] -right-[0vw] top-[2.7vw] rounded-[0.9vw] p-[0.8vw] opacity-0 transition-all duration-300  ${
              isProfile ? "opacity-100 block" : "hidden"
            }`}
          >
            <button
              className="ripple  w-full py-1.5 border rounded-[.2vw]"
              onClick={() => handleLogout()}
            >
              <p className="text-[.8vw] text-red-600">Logout</p>
            </button>
          </div>
          <div className="card flex !py-[0.4vw] items-center gap-[0.2vw]">
            <button
              className="ripple p-[0.2vw] rounded-md"
              onClick={() => {
                setIsWeather(!isWeather);
                setIsProfile(false);
              }}
            >
              <Cloud className="w-[1.2vw] h-[1.2vw] cursor-pointer" />
            </button>
            <button className="ripple p-[0.2vw] rounded-md">
              <MapPin className="w-[1.2vw] h-[1.2vw] cursor-pointer" />
            </button>
            <button className="ripple p-[0.2vw] rounded-md">
              <Bell className="w-[1.2vw] h-[1.2vw] cursor-pointer" />
            </button>
            <div
              className="flex gap-0.5 items-center cursor-pointer"
              onClick={() => {
                setIsProfile(!isProfile);
                setIsWeather(false);
              }}
            >
              <img src={MapPerson} className="w-[1.4vw] h-[1.4vw]" />
              <ChevronDown className="w-[0.8vw] h-[0.8vw]" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopController;
