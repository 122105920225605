import React from "react";
import CameraVideoOffIcon from "../../../../assets/icons/camera-video-off.svg?react";
import CameraVideoIcon from "../../../../assets/icons/camera-video.svg?react";

function CCTVListItem({ isActive, setCCTVActive, CCTVName }) {
  return (
    <div
      className={`${
        isActive ? "bg-[#E8EDF0]" : ""
      } flex p-[0.8vw] gap-[0.8vw] rounded-sm hover:bg-[#E8EDF0] cursor-pointer transition duration-200`}
      onClick={() => setCCTVActive(CCTVName)}
    >
      <div className="bg-white p-[0.6vw] w-[4vw] h-[4vw] rounded-[0.3vw] flex items-center justify-center">
        {CCTVName % 3 === 1 ? (
          <CameraVideoIcon className="cctv-on w-[1.2vw] h-[1.2vw]" />
        ) : (
          <CameraVideoOffIcon className="w-[1vw] h-[1vw]" />
        )}
      </div>
      <div className="">
        <h5 className="font-bold text-[1vw]">CCTV {CCTVName}</h5>
        <p className="text-[0.8vw] text-[#635F5F]">
          CCTV {CCTVName} (Lat -7.155 Lng 108.124)
        </p>
        <p className="text-[0.8vw] text-[#635F5F]">Tol Japek Km 55</p>
      </div>
    </div>
  );
}

export default CCTVListItem;
