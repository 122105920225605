import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null, // Ensure this is a plain object
  },
  reducers: {
    setLogin: (state) => {
      state.isAuthenticated = true;
      // state.user = action.payload; // Ensure payload is serializable
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
  },
});

export const { setLogin, setLogout } = authSlice.actions;
export default authSlice.reducer; // Ensure this is the default export
