import React from "react";
import Logo from "../../assets/images/logo-skota-data.svg";
import { Lock, Mail, User } from "lucide-react";
import GoogleLogo from "../../assets/icons/social-media/google.svg";
import FacebookLogo from "../../assets/icons/social-media/facebook.svg";
import { useNavigate } from "react-router-dom";
import UserIcon from "../../assets/icons/user.svg?react";
import LockIcon from "../../assets/icons/lock.svg?react";
import EmailIcon from "../../assets/icons/email.svg?react";
import { Eye, EyeOff } from "lucide-react";
import * as yup from "yup";
import { Formik } from "formik";

// Schema validasi menggunakan Yup
const validationSchema = yup.object({
  username: yup.string().required("Username harus diisi!"),
  email: yup
    .string()
    .email("Email tidak valid!")
    .required("Email tidak boleh kosong!"),
  password: yup
    .string()
    .min(8, "Password minimal 8 karakter")
    .required("Password tidak boleh kosong!"),
});

const defaultValues = {
  password: "asdQWE123#@!",
  email: "skota.dev@lintasarta.co.id",
  username: "skota",
};

function Register() {
  const navigate = useNavigate();
  const [isPassword, setIsPassword] = React.useState(true);

  async function handleRegister(fields) {
    try {
      console.log(fields);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className="bg-auth min-h-screen bg-cover bg-no-repeat relative grid grid-cols-3 py-[2vw] px-[2vw]">
      <div className="overlay bg-[#2563EB4D] w-full absolute top-0 left-0 min-h-screen"></div>
      <div className="col-span-2 z-[10]">
        <div className="logo">
          <img src={Logo} alt="" className="w-[9.7vw] h-[3.3vw]" />
        </div>
      </div>
      <div className="col-span-1 z-[10] px-[1vw] flex items-center">
        <div className="card w-full !bg-white !bg-opacity-90 !rounded-[1.2vw] !px-[1.6vw] !py-[2vw]">
          <h4 className="text-[#0F172A] text-[1.4vw] font-bold mb-[1.4vw]">
            Sign Up for an Account
          </h4>
          <Formik
            initialValues={{ username: "", email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleRegister(values)}
          >
            {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
              <form
                className="flex flex-col gap-[1.2vw]"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <div className="form-group">
                  <div className="relative">
                    <UserIcon
                      size={24}
                      className="absolute top-[.8vw] left-[.8vw] w-[1.2vw] h-[1.2vw]"
                    />
                    <input
                      type="text"
                      className="w-full border border-[#D1D1D1] rounded-[.5vw] py-[.7vw] px-[2.8vw] focus:outline-sky-500 text-[1vw]"
                      placeholder="Username"
                      name="username"
                      onChange={handleChange("username")}
                      onBlur={handleBlur("username")}
                      value={values.username}
                    />
                    {errors.username && (
                      <p className="text-[.9vw] text-red-600 mt-[.5vw]">
                        {errors.username}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="relative">
                    <EmailIcon
                      size={24}
                      className="absolute top-[.8vw] left-[.8vw] w-[1.2vw] h-[1.2vw]"
                    />
                    <input
                      type="text"
                      className="w-full border border-[#D1D1D1] rounded-[.5vw] py-[.7vw] px-[2.8vw] focus:outline-sky-500 text-[1vw]"
                      placeholder="Email"
                      name="email"
                      onChange={handleChange("email")}
                      onBlur={handleBlur("email")}
                      value={values.email}
                    />
                    {errors.email && (
                      <p className="text-[.9vw] text-red-600 mt-[.5vw]">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <div className="relative">
                    <LockIcon
                      size={24}
                      className="absolute top-[.8vw] left-[.8vw] w-[1.2vw] h-[1.2vw]"
                    />
                    {!isPassword ? (
                      <Eye
                        className="absolute top-[.8vw] right-[.8vw] w-[1.2vw] h-[1.2vw] cursor-pointer"
                        stroke="#64748B"
                        onClick={() => setIsPassword(true)}
                      />
                    ) : (
                      <EyeOff
                        className="absolute top-[.8vw] right-[.8vw] w-[1.2vw] h-[1.2vw] cursor-pointer"
                        stroke="#64748B"
                        onClick={() => setIsPassword(false)}
                      />
                    )}
                    <input
                      type={`${!isPassword ? "text" : "password"}`}
                      className="w-full border border-[#D1D1D1] rounded-[.5vw] py-[.7vw] px-[2.8vw] focus:outline-sky-500 text-[1vw]"
                      placeholder="Password"
                      name="password"
                      onChange={handleChange("password")}
                      onBlur={handleBlur("password")}
                      value={values.password}
                    />
                    {errors.password && (
                      <p className="text-[.9vw] text-red-600 mt-[.5vw]">
                        {errors.password}
                      </p>
                    )}
                  </div>
                  {/* <p className="text-[.9vw] text-[#64748B] mt-[.5vw]">
                    Your password must have at least 8 characters
                  </p> */}
                </div>
                <div className="form-group flex items-center gap-[.5vw]">
                  <input
                    type="checkbox"
                    className="self-start w-[1.6vw] h-[1.6vw]"
                  />
                  <label htmlFor="" className="text-[.9vw] text-[#64748B]">
                    By creating an account means you agree to the{" "}
                    <span className="font-semibold">Terms & Conditions</span>
                    and our{" "}
                    <span className="font-semibold">Privacy Policy</span>
                  </label>
                </div>
                <button
                  type="submit"
                  className="ripple text-white font-bold tracking-wider rounded-[.7vw] py-[1vw] bg-[#2563EB] text-[1vw]"
                >
                  Sign Up
                </button>
              </form>
            )}
          </Formik>
          {/* or sign up */}
          <div className="mt-[2.4vw] flex items-center">
            <div className="w-1/3 h-[.1vw] bg-[#E2E8F0]"></div>
            <div className="flex-1">
              <p className="text-[#64748B] text-[.9vw] text-center">
                Or sign up with
              </p>
            </div>
            <div className="w-1/3 h-[.1vw] bg-[#E2E8F0]"></div>
          </div>
          {/* OAuth */}
          <div className="buttons flex gap-[1vw] mt-[1.5vw]">
            <button className="ripple border py-[.8vw] rounded-[.9vw] bg-white border-[#E2E8F0] flex gap-[.5vw] items-center justify-center w-1/2">
              <img src={GoogleLogo} alt="" className="w-[1.6vw] h-[1.6vw]" />
              <p className="font-bold tracking-wider text-[1vw]">Google</p>
            </button>
            <button className="ripple border py-[.8vw] rounded-[.9vw] bg-white border-[#E2E8F0] flex gap-[.5vw] items-center justify-center w-1/2">
              <img src={FacebookLogo} alt="" className="w-[1.6vw] h-[1.6vw]" />
              <p className="font-bold tracking-wider text-[1vw]">Facebook</p>
            </button>
          </div>
          <p className="font-medium text-[1vw] text-center mt-[2vw]">
            Already have an account?{" "}
            <span
              className="text-[#2563EB] cursor-pointer"
              onClick={() => navigate("/auth/login")}
            >
              Log In
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
