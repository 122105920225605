import React from "react";
import { BreadCrumb, IFrame } from "../../../components";
function IOTDashboard() {
  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <BreadCrumb pageName={"IOT Dashboard"} type={"dashboard"} />
      </section>
      <div className="mt-[0.8vw]">
        <IFrame
          url={
            "https://devtb.skota.id/dashboard/d437f940-0c80-11ef-8331-6357dd23cd38?publicId=2abbd5b0-93c8-11ef-ba43-cf34b24b7189"
          }
        />
      </div>
    </>
  );
}

export default IOTDashboard;
