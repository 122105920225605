import axios from "axios";
import React from "react";

function IFrame({ url }) {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [url]);

  return (
    <div className="p-[1vw] bg-white shadow-md rounded-[.8vw]">
      <iframe
        src={url ? url : "https://www.w3schools.com"}
        title="W3Schools Free Online Web Tutorials"
        className="w-full h-[79vh]"
      />
    </div>
  );
}

export default IFrame;
