import React from "react";
import ZoomIcon from "../../../../assets/icons/zoom.svg?react";
import CCTVView from "../../../../assets/images/cctv-view.png";

function GridItem() {
  return (
    <div className="col-span-1">
      <div className="w-full relative">
        <div className="w-full bg-black p-[0.8vw] backdrop-blur-md bg-opacity-70 absolute">
          <p className="text-white text-[0.9vw]">
            CCTV1 (Lat -7.155 Lng -108.124)
          </p>
          <p className="text-white text-[0.9vw]">Tol Japek Km 55</p>
        </div>
        <div className="cursor-pointer p-[0.6vw] rounded-full flex justify-center items-center bg-black bg-opacity-70 bottom-[0.8vw] right-[0.8vw] absolute">
          <ZoomIcon className="w-[1vw] h-[1vw]" />
        </div>
        <img src={CCTVView} alt="" className="w-full h-full bg-cover" />
      </div>
    </div>
  );
}

export default GridItem;
