import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: true,
};

export const NavbarSlice = createSlice({
  name: "NavbarState",
  initialState,
  reducers: {
    showSidebar: (state, action) => {
      state.show = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSidebar } = NavbarSlice.actions;

export default NavbarSlice.reducer;
