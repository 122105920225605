import { ChevronDown, ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";

function Pagination({ paginationActive }) {
  return (
    <div className="flex justify-between items-center mt-[0.8vw]">
      <button className="ripple flex items-center gap-[0.2vw] border-[0.2vw] rounded-[0.3vw] px-[0.8vw] py-[0.2vw]">
        <p className="text-[0.9vw]">4 Baris</p>
        <ChevronDown className="w-[0.8vw] h-[0.8vw]" />
      </button>
      <div className="flex items-center gap-[0.2vw]">
        <button className="ripple p-[0.3vw] rounded-[0.3vw] border-[0.2vw]">
          <ChevronLeft className="w-[1vw] h-[1vw]" />
        </button>
        {[1, 2, 3, 4, 5].map((item, index) => (
          <button
            key={index}
            className={`ripple w-[2vw] h-[2vw] rounded-[0.3vw] border-[0.2vw] ${
              paginationActive === item
                ? "bg-gray-400 text-white border-gray-400"
                : ""
            }`}
          >
            <p className="">{item}</p>
          </button>
        ))}
        <button className="ripple p-[0.3vw] rounded-[0.3vw] border-[0.2vw]">
          <ChevronRight className="w-[1vw] h-[1vw]" />
        </button>
      </div>
    </div>
  );
}

export default Pagination;
