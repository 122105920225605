import React from "react";
import Logo from "../../assets/images/logo-skota-data.svg";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLogin } from "../../redux/slices/AuthSlice";

const defaultToken = "123456";

function OTPPage() {
  const navigate = useNavigate();
  const inputRefs = React.useRef([]);
  const [token, setToken] = React.useState(new Array(6).fill(""));
  const dispatch = useDispatch();

  const handleChangeToken = (e, index) => {
    const { value } = e.target;
    const newToken = [...token];

    if (value.match(/^[0-9]$/)) {
      newToken[index] = value;

      // Move focus to the next input field if not the last one
      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }

    setToken(newToken);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (token[index] === "") {
        if (index > 0) {
          // Move focus to the previous input field if empty
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newToken = [...token];
        newToken[index] = "";
        setToken(newToken);
      }
    }
  };

  async function handleSubmitToken() {
    try {
      if (
        token.length !== 6 ||
        token.some((item) => item === "" || item === undefined || item === null)
      ) {
        throw new Error("Kode OTP tidak valid!");
      }
      if (token.join("") !== defaultToken) {
        throw new Error("Kode OTP tidak ditemukan!");
      }
      toast.success("Kode OTP berhasil diverifikasi");
      dispatch(setLogin());
      setTimeout(() => {
        navigate("/map");
      }, 2000);
    } catch (e) {
      toast.error(e.message);
    }
  }
  return (
    <div className="bg-auth min-h-screen bg-cover bg-no-repeat relative py-[2vw] px-[2vw] flex flex-col w-full">
      <div className="overlay bg-[#2563EB4D] w-full absolute top-0 left-0 min-h-screen z-[1]"></div>
      <div className="logo z-[10]">
        <img src={Logo} alt="" className="w-[9.7vw] h-[3.3vw]" />
      </div>
      <div className="flex items-center justify-center h-[38vw] w-full">
        <div className="card w-[32vw] z-10 !px-[2.8vw] !py-[3vw] !rounded-[1.2vw] !bg-white !bg-opacity-90">
          <h4 className="text-[#0F172A] text-[1.4vw] font-bold mb-[.9vw]">
            Kode OTP
          </h4>
          <p className="text-[.9vw]">
            Masukan Kode OTP Yang Kami Kirim Melalui Email Anda.
          </p>
          <div className="mt-[.9vw] flex justify-between gap-[1vw]">
            {token.map((item, index) => (
              <input
                key={index}
                maxLength="1"
                value={item}
                ref={(el) => (inputRefs.current[index] = el)}
                onChange={(e) => handleChangeToken(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className="auth-input bg-white bg-opacity-90"
              />
            ))}
          </div>
          <button
            type="submit"
            className="ripple text-white font-bold tracking-wider rounded-[.7vw] py-[.7vw] bg-[#2563EB] my-[2.3vw] w-full text-[1vw]"
            onClick={() => handleSubmitToken()}
          >
            Verify
          </button>
          <p
            className="text-[#2563EB] text-center cursor-pointer text-[1vw]"
            onClick={() => navigate("/auth/login")}
          >
            Kembali ke sign in
          </p>
        </div>
      </div>
    </div>
  );
}

export default OTPPage;
