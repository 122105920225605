import React from "react";
import ListColumn from "../../../../assets/icons/list.svg?react";
import TwoColumn from "../../../../assets/icons/two-column.svg?react";
import ThreeColumn from "../../../../assets/icons/three-column.svg?react";
import FourColumn from "../../../../assets/icons/four-column.svg?react";

function ViewButton({ viewType, activeState, setShowItems }) {
  function renderIcon(viewType) {
    switch (viewType) {
      case "list":
        return (
          <ListColumn
            className={`w-[1vw] h-[1vw] transition ${
              activeState ? "cctv-view-active" : "cctv-view-inactive"
            }`}
          />
        );

      case "two-col":
        return (
          <TwoColumn
            className={`w-[1vw] h-[1vw] transition ${
              activeState ? "cctv-view-active" : "cctv-view-inactive"
            }`}
          />
        );

      case "three-col":
        return (
          <ThreeColumn
            className={`w-[1vw] h-[1vw] transition ${
              activeState ? "cctv-view-active" : "cctv-view-inactive"
            }`}
          />
        );

      case "four-col":
        return (
          <FourColumn
            className={`w-[1vw] h-[1vw] transition ${
              activeState ? "cctv-view-active" : "cctv-view-inactive"
            }`}
          />
        );

      default:
        break;
    }
  }
  return (
    <div
      className={`border border-[#D9D9D9] ${
        activeState ? "bg-[#D9D9D9]" : ""
      } w-[1.8vw] h-[1.8vw] rounded-[0.3vw] cursor-pointer flex items-center justify-center`}
      onClick={() => setShowItems(viewType)}
    >
      {renderIcon(viewType)}
    </div>
  );
}

export default ViewButton;
