import React from "react";

function POIButton({ data, pinData, handleFilter }) {
  return (
    <>
      {data.map((item, index) => (
        <div
          key={index}
          className="col-span-1 flex flex-col items-center cursor-pointer button-maps"
        >
          <button
            className={`ripple hover:bg-[#cfdcf6] rounded-[0.3vw] p-[0.7vw] flex items-center justify-center w-[3vw] h-[3vw] ${
              pinData.includes(item.name)
                ? "shadow-md border-[0.1vw] border-blue-900 bg-[#cfdcf6]"
                : "border border-[#CFCFCF] bg-white"
            }`}
            onClick={() => handleFilter(item.name)}
          >
            <item.icon className="w-[1.6vw] h-[1.6vw]" />
          </button>
          <div className="w-[3vw] whitespace-nowrap overflow-hidden">
            <p
              className={`text-center mt-[0.3vw] text-[0.8vw] ${
                pinData.includes(item.name) ? "text-blue-900" : ""
              } ${item.isAnimate ? "animate-marquee" : ""}`}
            >
              {item.name}
            </p>
          </div>
        </div>
      ))}
    </>
  );
}

export default POIButton;
