import cctv from "../assets/icons/pin/environment-cctv.svg";
import publicLamp from "../assets/icons/pin/environment-publiclamp.svg";
import trafficLight from "../assets/icons/pin/environment-trafficLight.svg";
import waterSensor from "../assets/icons/pin/environment-watersensor.svg";
//
import PFAirport from "../assets/icons/pin/pf-airport.svg";
import PFATM from "../assets/icons/pin/pf-atm.svg";
import PFBank from "../assets/icons/pin/pf-bank.svg";
import PFGasStation from "../assets/icons/pin/pf-gasstation.svg";
import PFHospital from "../assets/icons/pin/pf-hospital.svg";
import PFPharmacy from "../assets/icons/pin/pf-pharmacy.svg";
import PFTerminal from "../assets/icons/pin/pf-terminal.svg";
import PFWorship from "../assets/icons/pin/pf-worship.svg";
//
import EduGeneralShool from "../assets/icons/pin/edu-generalschool.svg";
import EduColleague from "../assets/icons/pin/edu-colleague.svg";
//
import ActMuseum from "../assets/icons/pin/act-museum.svg";
import ActPark from "../assets/icons/pin/act-park.svg";
import ActTour from "../assets/icons/pin/act-tour.svg";
import ActEvent from "../assets/icons/pin/act-event.svg";
import ActDestination from "../assets/icons/pin/act-destination.svg";
//
import LodMotel from "../assets/icons/pin/lodg-motel.svg";
import LodHotel from "../assets/icons/pin/lodg-Hotel.svg";
import { RoadmapMap, SatelliteMap, TerrainMap } from "../assets/images";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import axios from "axios";

export const dummyPOI = [
  {
    category: "cctv",
    name: "CCTV Lebak Bulus",
    type: "environment",
    location: {
      lat: -6.289433,
      lng: 106.7359167,
    },
    icon: cctv,
  },
  {
    category: "cctv",
    name: "CCTV Fatmawati",
    type: "environment",
    location: {
      lat: -6.2875561,
      lng: 106.7528254,
    },
    icon: cctv,
  },
  {
    category: "public lamp",
    name: "CCTV Cipete Raya",
    type: "environment",
    location: {
      lat: -6.2682747,
      lng: 106.7685324,
    },
    icon: publicLamp,
  },
  {
    category: "traffic light",
    name: "CCTV Haji Nawi",
    type: "environment",
    location: {
      lat: -6.2430361,
      lng: 106.771515,
    },
    icon: trafficLight,
  },
  {
    category: "water sensor",
    name: "CCTV Blok M",
    type: "environment",
    location: {
      lat: -6.2374901,
      lng: 106.771515,
    },
    icon: waterSensor,
  },
  {
    category: "cctv",
    name: "CCTV Senayan",
    type: "environment",
    location: {
      lat: -6.2299817,
      lng: 106.7739183,
    },
    icon: cctv,
  },
  {
    category: "sma",
    name: "SMA Nahdlatul Wathan",
    type: "people",
    location: {
      lat: -6.2224664,
      lng: 106.886352,
    },
    icon: EduGeneralShool,
  },
  {
    category: "sd",
    name: "SD Negeri 44 JAKARTA TIMUR",
    type: "people",
    location: {
      lat: -6.2226118,
      lng: 106.8356359,
    },
    icon: EduGeneralShool,
  },
  {
    category: "smp",
    name: "SMP Negeri 58 Jakarta",
    type: "people",
    location: {
      lat: -6.327866,
      lng: 106.8015603,
    },
    icon: EduColleague,
  },
  {
    category: "atm",
    name: "COMO Park",
    type: "smoke_fire",
    location: {
      lat: -6.2891132,
      lng: 106.8046007,
    },
    icon: PFATM,
  },
  {
    category: "bank",
    name: "Bintaro Plaza",
    type: "smoke_fire",
    location: {
      lat: -6.2732589,
      lng: 106.7398297,
    },
    icon: PFBank,
  },
  {
    category: "spbu",
    name: "Waduk Tiu Setu Cipayung",
    type: "smoke_fire",
    location: {
      lat: -6.3144169,
      lng: 106.9201609,
    },
    icon: PFGasStation,
  },
  {
    category: "apotek",
    name: "ALFAMART JEJALEN BARU",
    type: "smoke_fire",
    location: {
      lat: -6.2284173,
      lng: 106.9765087,
    },
    icon: PFPharmacy,
  },
  {
    category: "terminal",
    name: "Transera Waterpark",
    type: "smoke_fire",
    location: {
      lat: -6.1840469,
      lng: 106.9725605,
    },
    icon: PFTerminal,
  },
  {
    category: "tempat ibadah",
    name: "Masjid Istiqlal",
    type: "smoke_fire",
    location: {
      lat: -6.17017,
      lng: 106.8288151,
    },
    icon: PFWorship,
  },
  {
    category: "bandara",
    name: "Bandar Udara Internasional Soekarno–Hatta",
    type: "smoke_fire",
    location: {
      lat: -6.126551,
      lng: 106.6524809,
    },
    icon: PFAirport,
  },
  {
    category: "bandara",
    name: "Bandar Udara Pondok Cabe",
    type: "smoke_fire",
    location: {
      lat: -6.3500317,
      lng: 106.7026489,
    },
    icon: PFAirport,
  },
  {
    category: "rumah sakit",
    name: "Rumah Sakit Jakarta",
    type: "hat",
    location: {
      lat: -6.2183759,
      lng: 106.8136797,
    },
    icon: PFHospital,
  },
  {
    category: "taman",
    name: "Taman Menteng",
    type: "hat",
    location: {
      lat: -6.1964087,
      lng: 106.8267357,
    },
    icon: ActPark,
  },
  {
    category: "paket wisata",
    name: "Pasar Pramuka",
    type: "hat",
    location: {
      lat: -6.196974,
      lng: 106.8370246,
    },
    icon: ActTour,
  },
  {
    category: "museum",
    name: "Museum Sejarah Jakarta",
    type: "hat",
    location: {
      lat: -6.1352,
      lng: 106.7942456,
    },
    icon: ActMuseum,
  },
  {
    category: "events",
    name: "Museum Nasional Indonesia",
    type: "people",
    location: {
      lat: -6.1764021,
      lng: 106.8025357,
    },
    icon: ActEvent,
  },
  {
    category: "destinasi",
    name: "Ancol",
    type: "hat",
    location: {
      lat: -6.1229635,
      lng: 106.8197904,
    },
    icon: ActDestination,
  },
  {
    category: "motel",
    name: "Hotel Borobudur Jakarta",
    type: "hat",
    location: {
      lat: -6.1721557,
      lng: 106.7977202,
    },
    icon: LodMotel,
  },
  {
    category: "hotel",
    name: "DoubleTree by Hilton Jakarta Kemayoran",
    type: "people",
    location: {
      lat: -6.1378294,
      lng: 106.8375597,
    },
    icon: LodHotel,
  },
  {
    category: "hotel",
    name: "Grand Hyatt Jakarta",
    type: "people",
    location: {
      lat: -6.277169,
      lng: 106.7699251,
    },
    icon: LodHotel,
  },
];

export const GMapType = [
  {
    name: "Satellite",
    img: SatelliteMap,
  },
  {
    name: "Roadmap",
    img: RoadmapMap,
  },
  {
    name: "Hybrid",
    img: SatelliteMap,
  },
  {
    name: "Terrain",
    img: TerrainMap,
  },
];
export const OSMapType = [
  {
    name: "Light",
    img: TerrainMap,
    url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
  },
  {
    name: "OpenStreet",
    img: SatelliteMap,
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
  {
    name: "Dark",
    img: TerrainMap,
    url: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
  },
];

export async function EmbedSuperset(ref, id) {
  try {
    const dashboard = await embedDashboard({
      id: id,
      supersetDomain: import.meta.env.VITE_SKOTA_DATA_DOMAIN,
      mountPoint: ref.current,
      fetchGuestToken: async () => {
        const url = import.meta.env.VITE_GUEST_TOKEN;
        const response = await axios.post(url);
        return response.data;
      },
      dashboardUiConfig: {
        hideTitle: true,
        filters: {
          expanded: false,
        },
        // urlParams: {
        //     foo: 'value1',
        //     bar: 'value2',
        //     // ...
        // }
      },
    });
    console.log("Dashboard embedded successfully", dashboard);
  } catch (error) {
    console.error("Error embedding dashboard:", error);
  }
}
