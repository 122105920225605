import React from "react";
import { BreadCrumb } from "../../../components";
import { useNavigate } from "react-router-dom";
import SliderIcon from "../../../assets/icons/slider.svg?react";
import { Edit, Search, Trash } from "lucide-react";
import DataTable from "react-data-table-component";

import CameraVideoOffIcon from "../../../assets/icons/camera-video-off.svg?react";
import CameraVideoIcon from "../../../assets/icons/camera-video.svg?react";
import WaterSensorIconActive from "../../../assets/icons/water-sensor-active.svg?react";
import WaterSensorIconInActive from "../../../assets/icons/water-sensor-inactive.svg?react";
import PublicLampIcon from "../../../assets/icons/sidemaps/public-lamp.svg?react";
import TrafficLightIcon from "../../../assets/icons/sidemaps/traffic-light.svg?react";
import { OSMapEnvironment } from "./partials/OSMapEnvironment";

const columns = () => [
  {
    name: "Environment",
    selector: (row) => row.environment,
    sortable: true,
    minWidth: "21vw",
    cell: (row) => {
      const iconComponents = {
        CCTV: {
          ACTIVE: <CameraVideoIcon className="cctv-on" />,
          DOWN: <CameraVideoOffIcon className="w-[1.2vw] h-[1.2vw]" />,
        },
        "Water Sensor": {
          ACTIVE: <WaterSensorIconActive className="!w-[1.7vw] h-[1.7vw]" />,
          DOWN: <WaterSensorIconInActive className="!w-[1.7vw] h-[1.7vw]" />,
        },
        "Public Lamp": {
          ACTIVE: <PublicLampIcon className="!w-[1.7vw] h-[1.7vw] cctv-on" />,
          DOWN: <PublicLampIcon className="!w-[1.7vw] h-[1.7vw] cctv-off" />,
        },
        "Traffic Light": {
          ACTIVE: <TrafficLightIcon className="!w-[1.7vw] h-[1.7vw] cctv-on" />,
          DOWN: <TrafficLightIcon className="!w-[1.7vw] h-[1.7vw] cctv-off" />,
        },
      };

      const IconDisplay = iconComponents[row.category]?.[row.status] || "";
      return (
        <div className="flex  gap-[1vw] items-center my-[1vw]">
          <div className="bg-white w-[3vw] h-[3vw] rounded-[.4vw] border flex items-center justify-center">
            {IconDisplay}
          </div>
          <div className="content">
            <h5 className="font-bold text-[.9vw]">{row.environment.name}</h5>
            <p className="text-[.6vw] text-[#635F5F] my-[.4vw]">
              {row.environment.position}
            </p>
            <p className="text-[.6vw] text-[#635F5F] my-[.4vw]">
              {row.environment.placement}
            </p>
          </div>
        </div>
      );
    },
  },
  {
    name: "Category",
    selector: (row) => row.category,
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => (
      <p
        className={`${row.status === "ACTIVE" ? "text-black" : "text-red-500"}`}
      >
        {row.status}
      </p>
    ),
  },
];

const data = [
  {
    id: 1,
    environment: {
      name: "CCTV 1",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "CCTV",
    status: "DOWN",
  },
  {
    id: 2,
    environment: {
      name: "CCTV 2",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "CCTV",
    status: "ACTIVE",
  },
  {
    id: 3,
    environment: {
      name: "Water Sensor 1",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "Water Sensor",
    status: "DOWN",
  },
  {
    id: 4,
    environment: {
      name: "Water Sensor 2",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "Water Sensor",
    status: "ACTIVE",
  },
  {
    id: 5,
    environment: {
      name: "Public Lamp 1",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "Public Lamp",
    status: "DOWN",
  },
  {
    id: 6,
    environment: {
      name: "Public Lamp 2",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "Public Lamp",
    status: "ACTIVE",
  },
  {
    id: 7,
    environment: {
      name: "Traffic Light 1",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "Traffic Light",
    status: "DOWN",
  },
  {
    id: 8,
    environment: {
      name: "Traffic Light 2",
      get position() {
        return `${this.name} (Lat - 7.155, Lng - 108.1234)`;
      },
      placement: "Tol Japek Km 55",
    },
    category: "Traffic Light",
    status: "ACTIVE",
  },
];

function ListEnvironment() {
  const navigate = useNavigate();
  const [activeData, setActiveData] = React.useState(null);

  const handleRowClick = (row) => {
    setActiveData(JSON.stringify(row));
    // You can also navigate or perform other actions here
  };

  const conditionalRowStyles = [
    {
      // Style for the selected row
      when: (row) => activeData && row.id === JSON.parse(activeData).id,
      style: {
        backgroundColor: "#F4F4F4",
        cursor: "pointer",
      },
    },
    {
      // Style for rows that are NOT equal to activeData.id
      when: (row) => !activeData || row.id !== JSON.parse(activeData).id,
      style: {
        cursor: "pointer",
      },
    },
  ];
  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <div className="flex itemx-center justify-between w-full">
          <BreadCrumb
            pageName={"Environment Management"}
            type="configuration"
            subMenus={[
              {
                path: "/configuration/environment",
                name: "List Environment",
              },
            ]}
          />
          <button
            onClick={() => navigate("/configuration/environment/create")}
            className="ripple bg-[#7398FF] border  py-1.5 px-4 rounded-md text-[.8vw] text-white"
          >
            Add New Envinronment
          </button>
        </div>
      </section>
      <section className="mt-[1.2vw] w-full bg-white rounded-[.8vw] pl-[1.5vw] py-[1.5vw] shadow-md flex flex-wrap gap-[2vw] min-h-[78vh]">
        <div className="w-full flex gap-[2.4vw]">
          <div className="">
            <div className="top flex items-center gap-[1vw]">
              <button
                className={`ripple border border-[#D9D9D9] px-[0.6vw] py-[0.6vw] rounded-[0.3vw] cursor-pointer flex items-center justify-center gap-[0.2vw]`}
              >
                <SliderIcon className={`w-[1vw] h-[1vw] transition`} />
                <p className="text-[0.7vw]">Filterisasi</p>
              </button>
              <div className="search items-center relative rounded-[0.3vw] ">
                <Search
                  size={20}
                  className="pointer-events-none absolute top-1/2 transform -translate-y-1/2 left-2 text-gray-400"
                />
                <input
                  type="text"
                  className=" text-sm rounded-[0.3vw] border py-2 pl-8 bg-white focus:outline-blue-200"
                  placeholder="Search anything. . ."
                />
              </div>
            </div>
            <div className="mt-[1.2vw]">
              <DataTable
                columns={columns()}
                data={data}
                onRowClicked={handleRowClick}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
          {/* divider */}
          <div className="w-[.1vw] bg-[#CDCDCD]"></div>
          <div className="flex-1 pr-[1.5vw]">
            {activeData && (
              <>
                <div className="top flex items-center justify-between pb-[1vw] border-b-2">
                  <h2 className="text-[1.6vw] font-bold">
                    {JSON.parse(activeData)?.environment.name} Details
                  </h2>
                  <div className="buttons flex items-center gap-[.5vw]">
                    <button className="ripple p-[.5vw] rounded-full border">
                      <Trash className="w-[1.2vw] h-[1.2vw]" />
                    </button>
                    <button className="ripple p-[.5vw] rounded-full border">
                      <Edit
                        onClick={() =>
                          navigate(
                            `/configuration/environment/edit/${
                              JSON.parse(activeData)?.id
                            }`
                          )
                        }
                        className="w-[1.2vw] h-[1.2vw]"
                      />
                    </button>
                  </div>
                </div>
                <div className="mt-[1vw]">
                  <div className="h-[21.6vw]">
                    <OSMapEnvironment
                      position={[-6.1753924, 106.8245779]}
                      isScroll={false}
                      isDragging={false}
                    />
                  </div>
                  <div className="location bg-[#F3F7FF] p-[.6vw] rounded-[.4vw] border border-[#2563EB] mt-[1vw]">
                    <h4 className="font-bold text-[1vw]">Location</h4>
                    <p className="text-[.8vw]">
                      Latitude -6.10027121 Longitude 107.1587201
                    </p>
                    <p className="text-[.8vw]">Jalan Batu Ampar No 16</p>
                  </div>
                  <div className="info">
                    <h4 className="font-semibold text-[1.4vw] mt-[1.4vw]">
                      Environment Info
                    </h4>
                    <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                      Detail of environment
                    </p>
                    <div className="form-group mt-[1.2vw]">
                      <label
                        htmlFor=""
                        className="text-[#4D4D4D] text-[1vw] font-semibold"
                      >
                        Environment Name
                      </label>
                      <input
                        type="text"
                        className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] bg-[#E0E4EA] focus:outline-none"
                        placeholder="Input CCTV Source"
                        defaultValue={"CCTV 1"}
                        readOnly
                      />
                    </div>
                    <div className="form-group mt-[1.2vw]">
                      <label
                        htmlFor=""
                        className="text-[#4D4D4D] text-[1vw] font-semibold"
                      >
                        Source
                      </label>
                      <input
                        type="text"
                        className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] bg-[#E0E4EA] focus:outline-none"
                        placeholder="Input CCTV Source"
                        defaultValue={"rtsp://admin:12345@service-cctv/onvif"}
                        readOnly
                      />
                    </div>
                    <h4 className="font-semibold text-[1.4vw] mt-[1.4vw]">
                      Surveilence AI
                    </h4>
                    <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                      Enable AI Feature
                    </p>
                    <div className="form-group mt-[1.2vw]">
                      <label
                        htmlFor=""
                        className="text-[#4D4D4D] text-[1vw] font-semibold"
                      >
                        Vehicle Counting
                      </label>
                      <input
                        type="text"
                        className="border rounded-[.4vw] block w-full py-[.5vw] px-[1.2vw] mt-[.4vw] placeholder:text-[#7A7A7A] text-[.9vw] bg-[#E0E4EA] focus:outline-none"
                        placeholder="Input CCTV Source"
                        defaultValue={"rtsp://admin:12345@service-cctv/onvif"}
                        readOnly
                      />
                    </div>
                    <h4 className="font-semibold text-[1.4vw] mt-[1.4vw]">
                      Device Status
                    </h4>
                    <p className="text-[.8vw] text-[#7A7A7A] mb-[1.2vw]">
                      Set on/off device
                    </p>
                    <p className="text-[1vw]">On</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default ListEnvironment;
