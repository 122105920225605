import React from "react";
import TopController from "./partials/TopController";
import Map from "./Map";
import OSMap from "./OSMap";
import { useSelector } from "react-redux";
import { LintasartaLabel } from "../../assets/images";
import { feature } from "topojson-client";
import topoData from "./jakarta-simplified-topo.json";
import provinceTopoData from "./province-topo.json";

function Maps() {
  const mapType = useSelector((state) => state.map.mapType);
  const geoType = useSelector((state) => state.map.geoType);
  const [geoData, setGeoData] = React.useState(null);

  // Convert TopoJSON to GeoJSON
  React.useEffect(() => {
    if (geoType === "provinsi") {
      const geoJsonData = feature(
        provinceTopoData,
        provinceTopoData.objects.provinces
      );
      setGeoData(geoJsonData);
    } else if (
      geoType === "kabupaten" ||
      geoType === "kota" ||
      geoType === "kecamatan"
    ) {
      const geoJsonData = feature(topoData, topoData.objects.jakarta); // Adjust this based on your TopoJSON structure
      setGeoData(geoJsonData);
    } else {
      setGeoData(null);
    }
    // if (geoType > 0) {
    //   if (geoType.includes("provinsi")) {
    // const geoJsonData = feature(
    //   provinceTopoData,
    //   provinceTopoData.objects.provinces
    // ); // Adjust this based on your TopoJSON structure
    // console.log(geoJsonData);
    // // const filteredGeoData = {
    // //   ...geoJsonData,
    // //   features: geoJsonData.features.filter(
    // //     (feature) => feature.properties.kabkot
    // //   ),
    // // };
    // setGeoData(geoJsonData);
    //   }
    // }
  }, [geoType]);

  const getGeoJSONStyle = (feature) => {
    const colors = {
      "Jakarta Selatan": "#FF5733",
      "Jakarta Timur": "#33FF57",
      "Jakarta Pusat": "#3357FF",
      "Jakarta Barat": "#FF33A1",
      "Jakarta Utara": "#33FFF5",
    };

    return {
      fillColor: colors[feature.properties.kabkot] || "#CCCCCC",
      weight: 2,
      opacity: 1,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7,
    };
  };

  function renderMap() {
    switch (mapType) {
      case "GMap":
        return <Map geoData={geoData} style={getGeoJSONStyle} />;
      case "OSMap":
        return <OSMap geoData={geoData} />;
      default:
        return;
    }
  }

  return (
    <>
      <div className="w-full min-h-screen relative">
        <TopController />
        {renderMap()}
        <div className="bg-red-500">
          <img
            src={LintasartaLabel}
            alt="label"
            className="fixed bottom-7 right-5 z-[11] w-[9.7vw] h-[4vw]"
          />
        </div>
      </div>
    </>
  );
}

export default Maps;
