import React from "react";
import CCTVListItem from "./CCTVListItem";
import CCTVView from "../../../../assets/images/cctv-view.png";
import { Dot, TriangleAlert } from "lucide-react";

function ListView({ showItems, CCTVActive, setCCTVActive }) {
  const [menuActive, setMenuActive] = React.useState("Dashboard");
  return (
    <>
      {showItems === "list" && (
        <section className="mt-[1.2vw]">
          <div className="flex gap-[0.8vw] relative">
            <div className="w-[24vw]">
              {/* h-[calc(100vh-210px)] overflow-y-scroll */}
              <div className="flex flex-col gap-[0.4vw]">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
                  <CCTVListItem
                    key={index}
                    isActive={item === CCTVActive}
                    setCCTVActive={setCCTVActive}
                    CCTVName={item}
                  />
                ))}
              </div>
            </div>
            {/*  */}
            <div className="flex-1">
              {/* top */}
              <div className="flex gap-[0.8vw]">
                <div className="flex-1 w-[39.9vw] h-[31.5vw] bg-red-500">
                  <img
                    src={CCTVView}
                    alt=""
                    className="w-full h-full bg-cover"
                  />
                </div>
                <div className="flex flex-col gap-[0.4vw]">
                  <button className="text-[0.6vw] bg-[#1053F31A] border border-[#ADB6CC] bg-opacity-10 px-[2vw] py-[0.6vw] rounded-sm text-xs ripple">
                    START ANALYSIS
                  </button>
                  <button className="text-[0.6vw] bg-[#1053F31A] border border-[#ADB6CC] bg-opacity-10 px-[2vw] py-[0.6vw] rounded-sm text-xs ripple">
                    START STREAMING
                  </button>
                </div>
              </div>
              {/* bottom */}
              <div className="mt-[0.8vw]">
                <div className="flex items-center gap-[0.8vw]">
                  {["Dashboard", "Report"].map((item, index) => (
                    <button
                      key={index}
                      className={`${
                        menuActive === item
                          ? "bg-[#1053F31A] bg-opacity-10"
                          : ""
                      } px-[1vw] py-[0.6vw] text-[0.8vw] ripple rounded-[0.2vw]`}
                      onClick={() => setMenuActive(item)}
                    >
                      {item}
                    </button>
                  ))}
                </div>
                <div className="mt-[0.8vw]">
                  <div className="bg-[#F3F3F3] p-[1.4vw] rounded-[0.3vw]">
                    <div className="flex w-full justify-between pb-[0.6vw] border-b-[0.3vw]">
                      <div className="">
                        <h4 className="font-bold text-[1.4vw]">
                          CCTV {CCTVActive + 1}
                        </h4>
                        <p className="text-[0.9vw]">Device ID: 12345</p>
                      </div>
                      <div className="">
                        <h4 className="font-bold text-[1.4vw]">
                          Service Status
                        </h4>
                        <div className="flex items-center justify-end gap-[0.4vw]">
                          <div
                            className={`w-[0.6vw] h-[0.6vw] rounded-full ${
                              (CCTVActive + 1) % 3 === 1
                                ? "bg-green-400"
                                : "bg-red-400"
                            }`}
                          ></div>
                          <p className="font-bold text-[0.9vw]">
                            {(CCTVActive + 1) % 3 === 1 ? "On" : "Off"}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/*  */}
                    <div className="pb-[0.6vw] border-b-[0.3vw]">
                      <div className="ripple bg-[#FD3636] flex items-center gap-[0.4vw] justify-center rounded-[0.3vw] py-[0.4vw] mt-[0.8vw] text-[0.8vw] w-full text-white">
                        <p>Employee Without Clean Area Detected</p>
                        <TriangleAlert className="w-[1.2vw] h-[1.2vw]" />
                      </div>
                      <div className="flex items-center gap-[0.2vw] mt-[0.4vw]">
                        <p className="text-[0.8vw] text-[#747474]">
                          21/08/2022
                        </p>
                        <Dot className="w-[1vw] h-[1vw]" />
                        <p className="text-[0.8vw] text-[#747474]">9:49 AM</p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="ripple bg-[#FFF6C6] border border-[#EC9C22] flex items-center justify-between px-[0.8vw] rounded-[0.3vw] py-[0.6vw] mt-[0.8vw] text-[0.8vw] w-full text-white">
                      <p className="text-black font-semibold">
                        People Detected
                      </p>
                      <div className="bg-[#EC9C22] px-[0.8vw] py-1.5 rounded-[0.2vw]">
                        Undefined Employee
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default ListView;
