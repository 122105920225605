import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sideMenusConfiguration } from "../../utils/menus";

function SidebarDashboard() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [redirectingPath, setRedirectingPath] = React.useState(null);

  const handleClick = (path) => {
    setRedirectingPath(path);

    // Wait for the animation to finish before redirecting (e.g., 500ms)
    setTimeout(() => {
      navigate(path); // Navigate to the target route
    }, 500);
  };

  return (
    <div className="wrapper-menus px-[0.4vw] mt-[0.8vw] flex flex-col gap-[0.5vw]">
      {sideMenusConfiguration.map((item, index) => (
        <button
          key={index}
          onClick={() => handleClick(item.path)}
          className={`ripple line-clamp-1 w-full py-3 text-left px-4 text-[#9C9C9C] transition-all duration-500 ease-in-out
          ${
            pathname === item.path
              ? "bg-[#EDF1F5] text-black border-l-[.3vw] border-black font-semibold"
              : ""
          }
          ${
            redirectingPath === item.path
              ? "scale-95" // Fade out and slightly scale down the clicked button
              : "scale-100"
          } flex items-center gap-2 text-[0.9vw]`}
        >
          {item.icon && (
            <item.icon
              className={`w-[0.8vw] h-[0.8vw] ${
                pathname === item.path ? "svg-active" : ""
              }`}
            />
          )}
          {item.name}
        </button>
      ))}
    </div>
  );
}

export default SidebarDashboard;
