import React from "react";
import PeoplePin from "../../../assets/icons/people-pin.svg?react";
import RegionWide from "../../../assets/icons/region-wide.svg?react";
import RegionDensity from "../../../assets/icons/region-density.svg?react";
import { Calendar, ChevronDown, MapPin, Train } from "lucide-react";
import CameraVideoOffIcon from "../../../assets/icons/camera-video-off.svg?react";
import CameraVideoIcon from "../../../assets/icons/camera-video.svg?react";
import { SearchResult, SpotNews } from "../../../assets/images";
import { poiMenusDashboard, skotaIcons } from "../../../utils/menus";
import WeatherIcon from "../../../assets/icons/weather-icon.svg?react";
import { icon } from "leaflet";
import { BreadCrumb } from "../../../components";

function CityDashboard() {
  const data = [
    {
      title: "Jumlah Penduduk",
      amount: "500.000 Jiwa",
      date: "20 Oktober 2024",
      icon: PeoplePin,
    },
    {
      title: "Luas Wilayah",
      amount: "3.000 Km2",
      date: "20 Oktober 2024",
      icon: RegionWide,
    },
    {
      title: "Kepadatan Penduduk",
      amount: "500.000 Jiwa",
      date: "20 Oktober 2024",
      icon: RegionDensity,
    },
  ];
  return (
    <>
      <section className="flex justify-between items-center pb-[0.4vw] border-b-[0.2vw] border-gray-200">
        <BreadCrumb pageName={"Dashboard"} type={"dashboard"} />
      </section>
      <section className="mt-[1.2vw]">
        <div className="flex gap-[1vw] flex-wrap">
          <div className="news flex gap-[1.5vw] bg-white p-[1.2vw] rounded-[.9vw] shadow-md">
            <div className="spot-news w-[32.9vw]">
              <div className="w-full rounded-[.8vw] h-[17vw]">
                <img src={SpotNews} alt="" className="bg-cover w-full h-full" />
              </div>
              <h3 className="font-bold text-[1.2vw] line-clamp-2 mt-[1vw]">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem
                praesentium dolor officia cumque nam, in eius sequi mollitia
                rerum quam impedit repellat sint ut ratione necessitatibus
                culpa, molestiae ullam id?
              </h3>
              <div className="flex gap-[.6vw] mt-[1vw]">
                <Calendar className="w-[1.2vw] h-[1.2vw]" />
                <p className="text-[.8vw] text-[#635F5F]">18/04/2024</p>
              </div>
            </div>
            <div className="list-news flex flex-col gap-[1.5vw]">
              {[1, 2, 3, 4].map((item) => (
                <div key={item} className="wrapper flex gap-[.8vw] w-[19vw]">
                  <div className="flex-1">
                    <div className="w-[6.5vw] h-[4.9vw] rounded-[.35vw]">
                      <img
                        src={SearchResult}
                        alt=""
                        className="bg-cover w-full h-full"
                      />
                    </div>
                  </div>
                  <div className="list-news-content flex flex-col justify-between">
                    <h3 className="font-bold text-[.8vw] line-clamp-2">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Omnis temporibus incidunt quam dolorum. Voluptas
                      repellendus reiciendis in praesentium ipsa quae enim
                      quidem maiores molestiae, rerum dolore voluptatem ut
                      numquam velit.
                    </h3>
                    <div className="flex gap-[.6vw] mt-[1vw]">
                      <Calendar className="w-[1.2vw] h-[1.2vw]" />
                      <p className="text-[.8vw] text-[#635F5F]">18/04/2024</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="app-integrity">
            <div className="flex gap-[1.5vw]">
              <div className="w-[11vw] h-[8.4vw] bg-[#2563EB] overflow-hidden rounded-[.6vw] py-[1vw] px-[.8vw] relative">
                <p className="text-white text-[2.2vw]">
                  14 &deg; <sub className="text-[.8vw]">H23° L10°</sub>
                </p>
                <p className="text-white text-[.8vw]">Jakarta</p>
                <div className="absolute top-0 left-0 right-0 bottom-0">
                  <WeatherIcon className="sticky ml-[3vw] mt-[2.3vw] top-8 right-0 w-[7.5vw] h-[7.5vw]" />
                </div>
              </div>
              <div className="w-[11vw] h-[8.4vw] bg-[#323336] rounded-[.6vw] py-[1vw] px-[.8vw]">
                <p className="text-white text-[.8vw]">Clock</p>
                <h3 className="mt-[1vw] text-center font-bold text-white text-[1.9vw]">
                  19:00 PM
                </h3>
              </div>
            </div>
            <div className="mt-[1.5vw]">
              <h4 className="font-bold text-[1vw]">App Integrity</h4>
              <div className="mt-[1.2vw] grid grid-cols-5 gap-[1vw]">
                {skotaIcons.map((item, index) => (
                  <div
                    key={index}
                    className="col-span-1 flex flex-col items-center justify-center gap-[.4vw]"
                  >
                    <div
                      className={`${item.bg} w-[3.4vw] h-[3.4vw] rounded-[.4vw] flex items-center justify-center`}
                    >
                      <item.icon className="w-[3vw] h-[3vw]" />
                    </div>
                    <p className="text-[.7vw] text-center">
                      {item.name.split(" ")[0]} <br /> {item.name.split(" ")[1]}
                      <br />
                      {item.name.split(" ")[2] ? item.name.split(" ")[2] : null}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="region-information w-[15vw]">
            <div className="bg-white px-[1vw] py-[1.2vw] rounded-[.8vw] shadow-md">
              <h4 className="font-bold text-[1vw]">Informasi Wilayah</h4>
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="flex gap-4 items-center bg-[#F5F5F5] my-[1vw] p-4 rounded-[.7vw] w-full">
                    <div className="bg-[#E7E7E7] rounded-md w-[2.4vw] h-[2.4vw] flex items-center justify-center">
                      <item.icon className="w-[1.2vw] h-[1.2vw]" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <p className="text-[#64748B] font-semibold text-[0.7vw]">
                        {item.title}
                      </p>
                      <p className="text-[1vw] font-bold text-black">
                        {item.amount}
                      </p>
                      <p className="text-[0.7vw] text-[#64748B] text-md">
                        {item.date}
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="cctv w-[24vw]">
            <div className="bg-white px-[1vw] py-[1.2vw] rounded-[.8vw] shadow-md">
              <h4 className="font-bold text-[1vw]">CCTV</h4>
              <p className="text-[.9vw] mt-[.4vw]">56 CCTV in Total</p>
              <div className="mt-[2vw] flex">
                <div className="w-1/2 flex items-center gap-[1vw]">
                  <div className="bg-[#F4F4F4] p-[0.6vw] w-[4vw] h-[4vw] rounded-[0.3vw] flex items-center justify-center">
                    <CameraVideoIcon className="cctv-on w-[1.5vw] h-[1.5vw]" />
                  </div>
                  <div className="cctv-content">
                    <h5 className="font-semibold text-[1.4vw]">55</h5>
                    <p className="text-[.8vw] font-semibold text-[#848FAC]">
                      Active CCTV
                    </p>
                  </div>
                </div>
                <div className="w-1/2 flex items-center gap-[1vw]">
                  <div className="bg-[#FCE4E4] p-[0.6vw] w-[4vw] h-[4vw] rounded-[0.3vw] flex items-center justify-center">
                    <CameraVideoOffIcon className="w-[1.2vw] h-[1.2vw]" />
                  </div>
                  <div className="cctv-content">
                    <h5 className="font-semibold text-[1.4vw]">1</h5>
                    <p className="text-[.8vw] font-semibold text-[#848FAC]">
                      Disabled CCTV
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="poi">
            <div className="bg-white px-[1vw] py-[1.2vw] rounded-[.8vw] shadow-md">
              <h4 className="font-bold text-[1vw]">Point Of Interest</h4>
              <div className="grid grid-cols-3 mt-[1.6vw] gap-[.9vw]">
                {poiMenusDashboard.map((item, index) => (
                  <div key={index} className="col-span-1">
                    <div className="bg-[#F5F5F5] p-[.9vw] rounded-[.8vw] flex items-center gap-[.8vw]">
                      <div className="rounded-full bg-[#E4E3E3] p-[.6vw]">
                        <item.icon className="w-[1.4vw] h-[1.4vw]" />
                      </div>
                      <div className="">
                        <h5 className="font-medium text-[#7B7D8F] text-[.9vw]">
                          {item.name}
                        </h5>
                        <p className="font-bold text-[#0B061A] text-[1.2vw]">
                          {item.sum} Location
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="mt-4">
        <div className="bg-white w-full rounded-md shadow-md flex justify-between items-center py-[1.4vw]">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <div className="flex gap-4 items-center justify-center w-full">
                <div className="bg-[#E7E7E7] rounded-md w-[2.4vw] h-[2.4vw] flex items-center justify-center">
                  <PeoplePin className="w-[1.2vw] h-[1.2vw]" />
                </div>
                <div className="flex flex-col gap-1">
                  <p className="text-[#64748B] font-semibold text-[0.9vw]">
                    {item.title}
                  </p>
                  <p className="text-[1.2vw] font-bold text-black">
                    {item.amount}
                  </p>
                  <p className="text-[0.7vw] text-[#64748B] text-md">
                    {item.date}
                  </p>
                </div>
              </div>
              {index !== data.length - 1 && (
                <div className="divider h-[150px] w-[1px] bg-[#D6D6D6]"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </section>
      <section className="mt-4">
        <div className="flex gap-4">
          <div className="flex-1">
            <div className="bg-white rounded-md p-[1.2vw] shadow-md h-full">
              <div className="flex justify-between items-center">
                <h4 className="font-bold text-black text-[1.4vw]">
                  Line Charts
                </h4>
                <button className="ripple flex items-center gap-2 px-3 py-3 border rounded-md">
                  <p className="text-black text-[0.6vw]">Lorem Ipsum</p>
                  <Calendar color="#111827" className="w-[1vw] h-[1vw]" />
                </button>
              </div>
            </div>
          </div>
          <div className="w-[20vw]">
            <div className="bg-white rounded-md py-[1.2vw] px-[1.6vw] shadow-md">
              <h4 className="font-bold text-black text-[1.4vw]">
                Total Location
              </h4>
              <div className="mt-4 flex flex-col justify-center items-center gap-4">
                {[1, 2, 3].map((item) => (
                  <div
                    key={item}
                    className="w-full bg-[#F5F5F5] p-[0.8vw] flex gap-[1.2vw] justify-center items-center rounded-2xl"
                  >
                    <div className="bg-[#F0F0F0] rounded-full flex items-center justify-center p-[0.3vw]">
                      <MapPin className="w-[1.5vw] h-[1.5vw]" />
                    </div>
                    <div className="">
                      <p className="text-[0.9vw] font-medium text-[#7B7D8F]">
                        Wisata Alam
                      </p>
                      <p className="font-bold text-black text-[1.2vw]">
                        20 Location
                      </p>
                    </div>
                  </div>
                ))}
                <button className="w-full ripple bg-black text-white flex justify-center items-center gap-2 py-[0.5vw] rounded-xl">
                  <p className="text-[0.9vw]">More</p>
                  <ChevronDown
                    color="#FFFFFF"
                    className="w-[1.4vw] h-[1.4vw]"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default CityDashboard;
