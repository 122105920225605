import { configureStore } from "@reduxjs/toolkit";
import { MapSlice } from "./slices/MapSlice";
import { NavbarSlice } from "./slices/NavbarSlice";
import AuthSlice from "./slices/AuthSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, AuthSlice);

export const store = configureStore({
  reducer: {
    map: MapSlice.reducer,
    navbar: NavbarSlice.reducer,
    auth: persistedAuthReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
